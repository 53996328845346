@import "../../../styles/apx.scss";
.shopadditem {

  padding-left: $apx-440;

  .text-23 { line-height: $apx-30; }

  &__first { width: $apx-460; }
  &__second { width: $apx-310; }

  &__third {
    
    width: $apx-440;
    left: -$apx-50;
  
  }

  &__fourth { width: $apx-510; }

  &__button { margin-top: $apx-100; margin-left: -$apx-25; }

}

@media screen and ( max-width: 960px ) {

.shopadditem {

  padding-left: unset;

  .text-23 { line-height: $apx-m-20; }

  &__first { width: $apx-m-300; }
  &__second { width: $apx-m-300; }

  &__fm, &__sm{ width: $apx-m-300; }

  .text-24-m { width: 100%; margin-top: -$apx-m-50; left: -$apx-m-5; }

  .grayline {

    opacity: 0.1;
    margin: 0 auto;
    background: gray;
    height: $apx-m-2;
    width: $apx-m-250;
    margin-top: $apx-m-35;
  
  }

  &__button { margin-top: $apx-100; margin-left: -$apx-25; }

  }

}