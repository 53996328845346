@import "../../../styles/apx.scss";
.shopitemlist {

  .text-23 { line-height: $apx-30; }

  &__secondtext { width: $apx-600; }

  &__first { width: $apx-440; }

  &__second {
    
    width: $apx-440;
    height: $apx-480;
  
  }

  &__third {
    
    border-radius: $apx-30;
    width: $apx-440;
    left: -$apx-50;
  
  }

  &__button { margin-top: $apx-100; left: -$apx-50; }

}

@media screen and ( max-width: 960px ) {

  .shopitemlist {

    .text-right { text-align: left; }

    .mt-350 { margin-top: -$apx-m-65; }

    .text-23 { line-height: $apx-m-20; margin-left: unset; }
  
    &__secondtext { width: unset; }
  
    &__first { width: $apx-m-300; }

    &__second {
      
      width: $apx-m-300;
      height: $apx-m-300;
    
    }
  
    &__third {
      
      top: unset;
      left: unset;
      width: $apx-m-300;
      border-radius: $apx-m-22;
    
    }

    .grayline {

      opacity: 0.05;
      margin: 0 auto;
      background: gray;
      height: $apx-m-2;
      width: $apx-m-250;
      margin-top: $apx-m-25;
    
    }
  
    &__button { margin-top: $apx-m-50; left: -$apx-50; }
  
  }

}