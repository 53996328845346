@import "../../../styles/apx.scss";
.links {

  svg {
  
    width: $apx-53;
    height: $apx-48;
  
  }

  .warntext {
  
    top: -$apx-27;
    left: $apx-23;
    line-height: $apx-30;
  
  }

  &__main { width: $apx-1380; }

  &__buttons {
    
    padding-top: $apx-50;
  
    ul li { 
      
      left: -$apx-35;
      margin-top: $apx-30;
    
    }

    div {
    
      width: $apx-606;
      height: $apx-1240;
      border-radius: $apx-24;
      box-shadow: 0 $apx-1 $apx-16 rgba(0, 0, 0, 0.05);
    
    }

    &__www-short {
    
      width: $apx-120;
      height: $apx-120;
    
    }

    &__sm {

      width: $apx-440;
      height: $apx-120;
    
    }

    &__www-long {
    
      width: $apx-440;
      height: $apx-120;
    
    }

    &__sm-links {
    
      width: $apx-385;
      height: $apx-230;
    
    }
  
  }

}

@media screen and ( max-width: 960px ) {

  
  .links {

    padding-left: unset;

    svg {
  
      min-width: $apx-m-80;
      min-height: $apx-m-80;
    
    }

    &__main { width: $apx-m-313; }

    .warntext {
  
      top: -$apx-m-10;
      left: $apx-m-24;
      line-height: $apx-m-18;
      white-space: nowrap;
    
    }

    .mob-center { 
      
      left: -$apx-m-10;
      text-align: center;
    
    }

    &-ol__point { margin-top: -$apx-m-15; }

    &__buttons {
    
      padding-top: unset;
      flex-direction: column;
      margin-top: $apx-m-35;

      ul li { 
        
        left: $apx-m-10;
        margin-top: $apx-m-10;
      
      }

      div {
      
        height: unset;
        width: $apx-m-305;
        margin-bottom: $apx-m-42;
        border-radius: $apx-m-24;
        box-shadow: 0 $apx-m-1 $apx-m-16 rgba(0, 0, 0, 0.05);
      
      }

      &__www-short {
    
        width: $apx-m-180;
        height: $apx-m-180;
      
      }
  
      &__sm {

        width: $apx-m-300;
        height: $apx-m-88;
        margin-left: -$apx-m-3;
        margin-bottom: $apx-m-20;
      
      }
  
      &__www-long {
      
        width: $apx-m-300;
        height: $apx-m-88;
      
      }
  
      &__sm-links {
      
        width: $apx-m-300;
        height: $apx-m-180;
        margin-bottom: $apx-m-20;
      
      }

    }

  }

}