@import "../../../styles/apx.scss";
.shopaftersales {

  padding-left: $apx-20;

  .text-23 { line-height: $apx-30; }

  .lefttext { width: $apx-695; padding-left: $apx-65; }

  &__first, &__second, &__third, &__fourth { width: $apx-440; }

  &__button { margin-top: $apx-100; margin-left: -$apx-25; }

}

@media screen and ( max-width: 960px ) {

  .shopaftersales {

    padding-left: unset;

    .flex { display: unset; }
  
    .ml-40, .ml-50, .ml-100 { margin-left: unset; }

    .lefttext { width: unset; padding-left: unset; }

    .mt-minus { margin-top: -$apx-m-25; }

    .text-23 { line-height: $apx-m-20; }
  
    &__secondtext { width: $apx-600; }
  
    &__first {
      
      width: $apx-m-300;
      height: unset;
    
    }
  
    &__second { width: $apx-m-300; }
  
    &__third {
  
      width: $apx-m-300;
      left: unset;
    
    }
  
    &__fourth {
      
      width: $apx-m-300;
      height: unset;
    
    }
  
    &__button { margin-top: $apx-m-25; left: unset; }
  
  }

}