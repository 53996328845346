@import "../../../styles/apx.scss";

.contentplan {

  &__list {
  
    margin-left: -$apx-30;

    &__text {
    
      font-weight: 600;
      font-size: $apx-23;
      line-height: $apx-28;

      li {
    
        margin-bottom: $apx-23;
        span { color: #A6A6A6; }
        &.mobile { display: none !important; }
        &.desktop { display: list-item !important; }

      }
    
    }
  
  }

}

@media screen and ( max-width: 960px ) {
  
  .contentplan {

    &__list {
  
      margin-left: -$apx-m-10;
  
      &__text {

        font-size: $apx-m-18;
        line-height: $apx-m-22;
  
        li {

          margin-bottom: $apx-m-18;
          &.desktop { display: none !important; }
          &.mobile { display: list-item !important; }

          span {

            font-size: $apx-m-16;
            line-height: $apx-m-20;
          
          }
  
        }
      
      }
    
    }
 
  }

}