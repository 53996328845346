@import "../../../styles/apx.scss";
.addvideos { 

  ul { 
    
    left: -$apx-20; 
    li { line-height: $apx-30; }

  }

  &__main { width: $apx-1300; }

  .flex-shift { display: flex; }
  .left, .right { width: $apx-650; }

}

@media screen and ( max-width: 960px ) {
  
  .addvideos {

    .flex-shift { display: unset; }
    .left, .right { width: $apx-m-300; }

    .text-18-m { line-height: $apx-m-22; }
    .text-17-m { line-height: $apx-m-21; }

    ul { 
      
      left: -$apx-m-16;
      li { line-height: $apx-m-20; }
    
    }

    &__main {
      
      left: -$apx-m-30 ;
      min-width: ( $apx-m-300 + $apx-m-80 );
    
    }

  }

}