@import "../../../styles/apx.scss";
.shopsales {

  padding-left: $apx-20;

  .text-23 { line-height: $apx-30; }

  .textright {
    
    top: -$apx-240;
    left: $apx-500;

  }

  .textdown { top: -$apx-480; }
  .textup { margin-top: -$apx-850; }

  &__first { width: $apx-1265; z-index: 1; }

  &__second {
    
    z-index: 2;
    width: $apx-420;
    left: $apx-684;
    top: -$apx-365;
  
  }

  &__third {
    
    z-index: 3;
    top: -$apx-730;
    width: $apx-380;
  
  }

  &__fourth {
    
    z-index: 4;
    width: $apx-380;
    top: -$apx-930;
    left: $apx-666;
  
  }

  &__button { margin-top: $apx-55; margin-left: -$apx-25; }

}

@media screen and ( max-width: 960px ) {
.shopsales {

  padding-left: unset;

  .text-23 { line-height: $apx-m-20; }

  .textright {
    
    top: unset;
    left: unset;
    text-align: left;

  }

  .textdown { top: -$apx-480; }

  .textup { 
    
    top: -$apx-m-35;
    margin-top: unset;
    margin-bottom: -$apx-m-15; }

  &__first { width: $apx-m-300; border-radius: $apx-m-10; }

  &__second {

    left: unset;
    top: $apx-m-25;
    width: $apx-m-300;
  
  }

  &__third {

    top: $apx-m-25;
    width: $apx-m-300;
    margin-bottom: $apx-m-140;
  
  }

  &__fourth {

    width: $apx-m-300;
    top: -$apx-m-65;
    left: unset;
  
  }

  &__button { margin-top: $apx-m-5; margin-left: unset; }

  }

}