@import "../../../styles/apx.scss";
.shopcats {

  padding-left: $apx-20;

  .text-23 { line-height: $apx-30; }

  &__first { width: $apx-440; }

  &__second {
    
    width: $apx-440;
    height: $apx-480;
    left: $apx-130;
  
  }

  &__third { width: $apx-440; }

  &__button { margin-top: $apx-100; margin-left: -$apx-50; }

}

@media screen and ( max-width: 960px ) {

  .shopcats {

    padding-left: unset;

    .ml-80, .ml-88 { margin-left: unset; }

    .mt-minus { margin-top: -$apx-m-15; }
  
    .text-23 { line-height: $apx-m-20; }
  
    &__first { width: $apx-m-300; }
  
    &__second {
      
      width: $apx-m-300;
      height: unset;
      left: unset;
    
    }
  
    &__third, &__fourth { width: $apx-m-300; }
  
    &__button {

      margin-left: unset;
      margin-top: $apx-m-35;

    }
  
  }

}