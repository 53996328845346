@import "../../../styles/apx.scss";

//общие стили
.mt-special {

  margin-top: -$apx-5;
  margin-left: $apx-2;
  margin-bottom: -$apx-20;

}

.header-menu {

  top: 0;
  left: 0;
  height: 100vh;
  overflow: auto;
  width: $apx-424;
  padding: $apx-5 $apx-5 $apx-25 $apx-25;
  box-shadow: $apx-1 $apx-1 $apx-25 $apx-5 rgba(150, 150, 150, 0.135);
  background: #fff;
  z-index: 100;

  &__logo {
  
    min-width: $apx-55;
    max-width: $apx-60;
    min-height: $apx-55;
    max-height: $apx-60;
    margin-top: $apx-10;
    margin-right: $apx-16;
  
  }

  &__text { margin: $apx-12 0 0 -$apx-16; }

  &__subtext { line-height: 125%; }

  &__toggle {
  
    &--opened { display: flex !important; }
    &--closed { display: none !important; }
  
  }

  &__opener, &__closer {
  
    top: $apx-28;
    left: $apx-8;
    width: $apx-20;
    height: $apx-20;
    cursor: pointer;
    position: absolute;

    &--opened { display: flex !important; }
    &--closed { display: none !important; }

    &_big {
    
      width: $apx-24;
      height: $apx-24;
      top: $apx-35;
      left: $apx-10;
    
    }
  
  }

  &__body {
  
    height: 100%;
    overflow: auto;
    white-space: pre-line;
    padding: $apx-1 $apx-3 0;

    &__text {
  
      padding-left: $apx-16;
      white-space: pre-line;
      margin-bottom: 0;

      &__link {
      
        &:hover .header-menu__body__text__link__subtext {
          color: #ff9b3d;
          
        }    
        
        text-decoration: unset;        
        &--active .header-menu__body__text__link__subtext {

          font-weight: 600;
          color: #FF7A00 !important;

        }
  
      }
      
    }
  
    &__subtext {
    
      padding-left: $apx-38;
      line-height: 125%;
      
    }
  
  }

}

.accordeon--opened > .accordeon-head { .header-menu__opener { display: none; } }

@media screen and ( max-width: 960px ) {

  .header-menu {

    width: 100%;
    height: unset;
    padding: unset;
    box-shadow: $apx-m-1 $apx-m-1 $apx-m-25 $apx-m-5 rgba(150, 150, 150, 0.135);

    &--opened { height: 100vh; }

    .accordeon + .accordeon {  margin-top: $apx-m-8; }
  
    &__logo {
    
      min-width: $apx-m-38;
      max-width: $apx-m-40;
      min-height: $apx-m-38;
      max-height: $apx-m-40;
      margin: unset !important;
    
    }

    &__border {
    
      align-items: center;
      justify-content: center;
      padding: $apx-m-22 $apx-m-20 $apx-m-12;
      box-shadow: 0 0 $apx-m-8 rgba(0, 0, 0, 0.08);
    
    }

    &__titles {
  
      flex-grow: 1;
      margin-left: $apx-m-12;

    }
  
    &__text {
  
      margin: unset;
      font-size: $apx-m-18;
      line-height: $apx-m-20;
    
    }
  
    &__subtext {

      margin: unset;
      font-size: $apx-m-13;
      line-height: $apx-m-15;
 
    }

    &__closer-button {
    
      width: $apx-m-21;
      height: $apx-m-21;
    
    }

    &__opener-button {
    
      width: $apx-m-20;
      height: $apx-m-36;
      margin-right: $apx-m-11;

      &__text {
    
        color: #00A3FF;
        margin: $apx-m-1;
        font-size: $apx-m-14;
        line-height: $apx-m-8;

      }

      &__liner {
      
        width: $apx-m-36;
        height: $apx-m-1;
        margin: $apx-m-1;
        border-radius: $apx-m-1;
        background: #00A3FF;
        opacity: 0.33;

        &__bold { opacity: 0.66; }
        &__thin { opacity: 0.11; }
      
      }
    
    }
  
    &__toggle {
    
      &--opened { display: flex !important; }
      &--closed { display: none !important; }
    
    }
  
    &__opener, &__closer {
    
      width: $apx-m-18;
      height: $apx-m-18;
      top: $apx-m-3;
      left: $apx-m-8;
  
      &_big {
      
        width: $apx-m-24;
        height: $apx-m-24;
        top: $apx-m-28;
        left: $apx-m-10;
      
      }
    
    }
  
    &__body {
      
      height: 100%;
      padding: $apx-m-5 $apx-m-23;

      &--closed { display: none; }

      &--opened {
        
        height: 100vh;
        display: flex;
        overflow: auto;
      
      }
  
      &__text {
    
        padding-left: unset;
        font-size: $apx-m-18;
        line-height: $apx-m-20;
        margin-left: -$apx-m-12;
      
      }
    
      &__subtext {
    
        font-size: $apx-m-18;
        line-height: $apx-m-20;
        padding-left: $apx-m-28;
      
      }

    }
  
  }
  
}