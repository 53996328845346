@import "./apx.scss";

@import "./text.scss";
@import "./tailwind.scss";

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600;700;800;900&display=swap');


* { box-sizing: border-box; }

html,
body,
#root {

  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100%;
  color: #616161;
  user-select: none;
  overflow-x: hidden;
  font-family: Montserrat;

}

.inline { display: inline !important; }

.desktop {
    
  display: block !important;
  &.flex { display: flex !important; }

}

.mobile { display: none !important; }

.roboto { font-family: Roboto; }

.pointer { cursor: pointer; }

.montserrat {

  font-family: Montserrat;
  position: relative;
  top: $apx-1;

}

.nowrap { white-space: nowrap; }

.link {

  display: inline-block;
  text-decoration: none;
  border-bottom: $apx-2 solid #0075FF;
  max-height: $apx-26;

}

.scrollAnchor {
  position: relative;
  top: -10px;
}

::-webkit-scrollbar { width: $apx-10; }

::-webkit-scrollbar-track {
  border-radius: $apx-25;
  background-color: #ffedb8 !important;
}

::-webkit-scrollbar-thumb {
  border-radius: $apx-25;
  background-color: #ff9924f8 !important;
}

::-webkit-scrollbar-thumb:hover {
  background: #ff8800f8 !important;
}

@media screen and ( max-width: 960px ) {  

  .desktop { display: none !important; }

  .mobile {
    
    display: block !important;
    &.flex { display: flex !important; }
  
  }

  .link { display: inline !important; }
  
}