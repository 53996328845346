@import "../../../styles/apx.scss";
.shift {

  padding-left: $apx-420;

  &__main { width: $apx-1400; }

  ul li { 
      
    top: $apx-5;
    left: -$apx-16;
    line-height: $apx-30;
    display: list-item !important;

    &:nth-child(2) { top: $apx-20; }
    &:nth-child(3) { top: $apx-35; }
  
  }

}

@media screen and ( max-width: 960px ) {
  .shift {

    padding-left: unset;

    &__button { display: none !important; }

    .text-16-m { line-height: $apx-m-20; }

    ul {

      left: -$apx-m-20;
      margin-bottom: $apx-m-35;
    
      li { 
        
        left: unset;
        line-height: $apx-m-25;
        display: list-item !important;
        &:nth-child(2) { top: $apx-m-25; }
        &:nth-child(3) { top: $apx-m-45; }
      
      }

    }

  }

}