@import "../../../styles/apx.scss";
.backgroundpage {

  padding-left: $apx-420;

  .text-23 { line-height: $apx-30; }

  svg {
  
    width: $apx-55;
    height: $apx-55;
    margin-right: $apx-25;
  
  }

  ul {
  
    left: -$apx-15;

    li { margin-bottom: $apx-10; }
  
  }

  &__warntext { 

    &__up { top: -$apx-1; }
    &__down { top: $apx-3; }

  }

  &__google { width: $apx-1090; }
  &__examples { width: $apx-1500; left: -$apx-50; }

  &__button { margin-top: $apx-50; }

}

@media screen and ( max-width: 960px ) {
  
  .backgroundpage {

    padding-left: unset;

    svg {
  
      min-width: $apx-m-60;
      min-height: $apx-m-60;
    
    }

    .desktop-desk { display: none; }

    &__warn, &__google { width: $apx-m-310; }

    &__example { width: $apx-m-300; }

    .text-23 { line-height: $apx-m-26; }
    .text-16-m { line-height: $apx-m-22; }
    .text-bold-mob { font-weight: 700; }
    .font-medium-mob { font-weight: 600; }
    .text-orange-desk { color: #616161; }

    ul {
    
      left: -$apx-m-25;
      li { margin-bottom: $apx-m-13; }
    
    }

    .mob-cen { 
      
      text-align: center; 
      &__lefter { left: -$apx-m-6; }

    }
    
  }

}