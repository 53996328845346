@import "../../../styles/apx.scss";
.landinfo {

  &__warn {

    width: $apx-57;
    height: $apx-57;

  }

  .text-blue {
    
    margin-left: $apx-10;
    line-height: $apx-30;

  }

  &__textblock {

    width: 100%;
    padding: $apx-30;
    border-radius: $apx-25;
    margin-top: $apx-30;

    &_purple { background: #FFF6F6; }
    &_yellow { background: #FFFEF6; }

  }

  ul li {

    margin-left: -$apx-25;
    margin-bottom: $apx-25;

  }

  .li { flex-wrap: wrap; }

  .text-gray__notabene { color: #A6A6A6; }

}

@media screen and ( max-width: 960px ) {
  .landinfo {

    max-width: $apx-m-300;
    
    &__warn {
  
      top: $apx-m-10;
      width: $apx-m-60;
      height: $apx-m-60;
  
    }
  
    .text-blue {
      
      margin-left: unset;
      line-height: $apx-m-30;
  
    }
  
    &__textblock {
  
      width: 118%;
      padding: $apx-m-20;
      padding-top: $apx-m-20;
      padding-bottom: $apx-m-10;
      margin-left: -$apx-m-25;
      margin-top: unset;
      border-radius: unset;

      &__text_yellow {
        
        text-align: center;
        margin-top: $apx-m-5;
        margin-bottom: $apx-m-35;
      
      }
  
    }
  
    ul {

      margin-left: -$apx-25;
      
      li {
    
        font-size: $apx-m-14;
        line-height: $apx-m-20;
        margin-bottom: $apx-m-15;
    
      }
      
    }

    .li { display: none; }

    .text-24 {

      font-size: $apx-m-24;
      line-height: $apx-m-28;
    
    }
  
    .text-22 {
    
      font-size: $apx-m-16;
      line-height: $apx-m-20;

      span { line-height: $apx-m-10 !important; }
    
    }

    .text-20 {
    
      font-size: $apx-m-15;
      line-height: $apx-m-18;
      margin-left: $apx-m-16;

      span { display: none; }
    
    }

    .text-14 {
    
      font-size: $apx-m-14;
      line-height: $apx-m-18;
      margin-bottom: $apx-m-40;
    
    }

    .text-purple { text-align: center; }

    .text-gray__notabene { white-space: nowrap; }

    .mt-minus { margin-top: -$apx-m-5 !important; }

    &__button {

      .text-24 {
      
        white-space: unset;
        font-size: $apx-m-15;
        line-height: $apx-m-18;
      
      }

    }
  
  }

}