@import "../../../styles/apx.scss";
.addimages { 
  
  svg {
  
    width: $apx-69;
    height: $apx-69;
  
  }

  ul { 
    
    left: -$apx-20; 
    li { line-height: $apx-30; }

  }

  &__main { width: $apx-1300; }

}

@media screen and ( max-width: 960px ) {
  .addimages {

    svg {
  
      width: $apx-m-200;
      height: $apx-m-200;
    
    }

    .text-32-m { left: -$apx-m-10; }
    .text-18-m { line-height: $apx-m-20; }

    ul { left: -$apx-m-35; }

    &__main { width: $apx-m-300; margin-left: $apx-m-5; }
    &__warn { width: $apx-m-320; margin-left: -$apx-m-5; }

  }

}