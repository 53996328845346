@import "../../../styles/apx.scss";
.shopitemquestions {

  padding-left: $apx-20;

  .text-23 { line-height: $apx-30; }

  &__secondtext { width: $apx-600; }

  &__first {
    
    width: $apx-440;
    height: $apx-350;
  
  }

  &__second { width: $apx-430; }

  &__third {

    width: $apx-430;
    left: $apx-90;
  
  }

  &__fourth {
    
    width: $apx-440;
    height: $apx-120;
  
  }

  &__button { margin-top: $apx-100; left: -$apx-50; }

}

@media screen and ( max-width: 960px ) {

  .shopitemquestions {

    padding-left: unset;
  
    .ml-30, .ml-60, .ml-80 { margin-left: unset; }

    .mt-minus { margin-top: 0; }

    .text-23 { line-height: $apx-m-20; }
  
    &__secondtext { width: $apx-600; }
  
    &__first {
      
      width: $apx-m-300;
      height: unset;
    
    }
  
    &__second { width: $apx-m-300; }
  
    &__third {
  
      width: $apx-m-300;
      left: unset;
    
    }
  
    &__fourth {
      
      width: $apx-m-300;
      height: unset;
    
    }
  
    &__button { margin-top: $apx-m-25; left: unset; }
  
  }

}