@import "../../../styles/apx.scss";

.preparation__actions {

  width: 100%;

  &__col {

    &:first-child { width: $apx-642; }
    &:last-child { padding-left: $apx-42; }

    &__number { 
      
      width: $apx-28;
      top: -$apx-12;
      left: 0;
    
    }

  }

}

.preparation__actions_tip svg {

  top: $apx-3;
  left: $apx-2;
  width: $apx-26;
  height: $apx-30;
  margin-right: $apx-16;

}

.hidden {

  opacity: 0;
  width: $apx-663;
  margin-right: $apx-41;

}

@media screen and ( max-width: 960px ) {  

  .preparation__actions {

    &.mt-54 { margin-top: $apx-m-38; }

    &.text-47 { margin-top: $apx-m-138; }

    &__col {
  
      &:first-child,
      &:last-child {
        
        width: 100%;
        padding-left: unset; 

      }

      &:last-child { margin-top: $apx-m-43; }
  
      &__number { 
        
        width: $apx-m-24;
        top: -$apx-m-4;
        left: 0;
      
      }

      &.mt-38 { margin-top: $apx-m-30; }
  
    }
  
  }
  
  .preparation__actions_tip {
    
    svg { display: none; }
    
    .text-23, .text-17 {

      font-size: $apx-m-15;
      line-height: $apx-m-16;

    }

  .button_container {

    &.mt-122 { margin-top: $apx-m-54; } }

  }
  
}