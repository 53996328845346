@import "../../../styles/apx.scss";
.newsite {

  padding-left: $apx-425;
  
  .text-23 { line-height: $apx-28; }

  ul { margin-left: -$apx-20; }

  &__ul {
  
    margin-top: $apx-28;
    margin-left: unset !important;

    li {
   
      margin-bottom: $apx-10;
      line-height: $apx-35;
    
    }
  
  }

  &__text-reverse div { margin-bottom: -$apx-30; }
  &__text-minus { margin-bottom: -$apx-5 !important; }

  &__warn {
  
    svg {
    
      width: $apx-60;
      height: $apx-60;
      margin-right: $apx-15;
    
    }
  
  }

  &__changeaddress { width: $apx-1324; }
  &__constructor { 
    
    width: $apx-1391;
    margin-left: -$apx-25;
    
    &__info {
    
      top: $apx-15;
      left: $apx-432;
      padding-left: $apx-20;
      border-left: $apx-15 solid rgba(50, 150, 255, 0.4);
      z-index: 1000;
    
    }
  
  }

  &__avatar { width: $apx-375; }
  &__text { width: $apx-360; height: $apx-240; }
  &__title { width: $apx-360; }
  &__subtitle { width: $apx-360; height: $apx-600; }
  &__examples { width: $apx-1300;}

  &__point {
  
    width: $apx-7;
    height: $apx-7;
    margin-top: -$apx-25;
    margin-right: $apx-15;
    border-radius: $apx-100;
    background-color: #616161;

    &__minus { margin-top: -$apx-55; }
  
  }

  &__button { margin-top: $apx-100; }

}

@media screen and ( max-width: 960px ) {
  .newsite {

    padding-left: unset;

    .text-23 { line-height: unset; }
    .text-14-m { line-height: $apx-m-20; }
    .text-red-blue { color: #0075FF; }
    .text-central {

      left: -$apx-m-25;
      text-align: center;
      margin: unset !important;
      margin-top: $apx-m-35 !important;

      &__lefter { margin-left: -$apx-m-46 !important; }
      &__leftest { margin-left: -$apx-m-60 !important; }
    
    }

    &__warn {
  
      svg {
      
        width: $apx-m-60;
        height: $apx-m-60;
        margin-right: $apx-m-15;
      
      }

    }

    &__info, &__exam { width: $apx-m-300; }
    &__avatar, &__change, &__text-mob { width: $apx-m-315; }
    
    &__title {
      
      width: $apx-m-310;
      margin-top: -$apx-m-35;
      margin-left: -$apx-m-5;
    
    }
  
    &__subtitle {
      
      width: $apx-m-310;
      height: ( $apx-m-300 + $apx-m-230 );
    
    }

    &__constructor__info {
    
      position: unset;
      margin-top: $apx-m-10;
      margin-left: -$apx-m-30;
      padding: $apx-m-30 $apx-m-30 0;
      background: rgba(50, 150, 255, 0.1);

      ul { margin-left: -$apx-m-18; }
      div { margin-bottom: $apx-m-22 !important; }
    
    }

    &__ul { margin-top: $apx-m-15 !important; }

    &__ol {  
      
      margin-left: -$apx-m-30;
      padding: $apx-m-15 $apx-m-30 $apx-m-15;
      background: rgba(75, 175, 85, 0.1) !important;

      p { line-height: $apx-m-20; }
    
    }

    &__button { margin-top: $apx-m-80; }

  }

}