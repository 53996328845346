@import "../../../styles/apx.scss";
.payment-prepay {

  padding-left: $apx-420;

  .mt-minus { margin-top: -$apx-200; }
  .ml-150 { margin-left: $apx-150; }
  .mt-100 { margin-left: -$apx-10; }

  &__img {
    
    width: $apx-430;

    &__sec {
    
      top: -$apx-280;
      left: $apx-100;
    
    }

    &__yoca {
    
      width: $apx-1360;
      margin-top: $apx-30;
    
    }

    &__off {
    
      width: $apx-460;
    
    }

  }

}

@media screen and ( max-width: 960px ) {

  .payment-prepay {

    padding-left: unset;

    .flex { display: unset; }

    .mt-minus { margin-top: $apx-m-25; }
    .ml-250 { margin-left: unset; }
    .ml-150 { margin-left: unset; }
    .mt-100 { margin-left: unset; }

    &__img {
      
      width: $apx-m-305;
    
      &__sec {
    
        top: unset;
        left: unset;
      
      }
  
    }

  }

}