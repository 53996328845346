@import "../../../styles/apx.scss";
.payment-secret {

  padding-left: $apx-420;

  &__img { width: $apx-1360; }

  .user-on { user-select: text !important; }

}

@media screen and ( max-width: 960px ) {

  .payment-secret {

    padding-left: unset;

    &__img { width: $apx-m-305; }

  }

}