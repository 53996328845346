@import "../../../styles/apx.scss";
.background {

  .z { z-index: 10; }
  .text-23 { line-height: $apx-30; }

  ul {
  
    left: -$apx-19;
    li { margin-bottom: $apx-13; }
  
  }

  &__header { 
    
    width: $apx-1133;

    &-image { 
      
      z-index: 0;
      top: $apx-42;
      left: -$apx-50;
      width: $apx-1300;
    
    }

  }

  &__eval {
    
    z-index: 10;
    top: -$apx-25;
    width: $apx-1300;
    &__z { z-index: 1; }

  }

  &__google { width: $apx-1300; }
  &__check { width: $apx-1300; }

  .if-msg {
  
    margin-top: -$apx-100;
    margin-bottom: -$apx-50;
  
  }

  &__button { margin-top: $apx-50; }

}

@media screen and ( max-width: 960px ) {
  
  .background {

    padding-left: unset;

    svg {
  
      min-width: $apx-m-80;
      min-height: $apx-m-80;
    
    }

    .text-23 { line-height: $apx-m-26; }
    .text-20-m { line-height: $apx-m-26; }
    .text-16-m { line-height: $apx-m-21; }
    .text-15-m { line-height: $apx-m-20; }
    .text-subtext { margin-top: -$apx-m-16; &__small { margin-top: -$apx-m-6; }}

    ul, ol {
    
      left: -$apx-m-25;
      li { margin-bottom: $apx-m-13; }
    
    }
  
    &__header { 
      
      width: $apx-m-320;

      &-image { 
        
        top: $apx-m-2;
        width: $apx-m-320;
      
      }
    
    }
  
    &__eval {
      
      z-index: 10;
      top: -$apx-25;
      width: $apx-1300;
      flex-direction: column;
      &__z { z-index: 1; }

      &-mob, &-desk { width: $apx-m-305; top: -$apx-m-14; }
      &-desk { top: -$apx-m-41; }
  
    }

    .text-right { text-align: left; }
    .mt-minus { margin-top: -$apx-m-25; }
  
    &__google { 

      &-first {
      
        width: $apx-m-330;
        margin-left: -$apx-m-20;
      
      }

      &-second { width: $apx-m-305; }

     }

    &__check { 

      &-first, &-second, &-third { width: $apx-m-305; }

     }
  
    .if-msg {
    
      width: $apx-m-355;
      padding: $apx-m-25 0;
      margin-bottom: unset;
      margin-top: $apx-m-5;
      margin-left: -$apx-m-25;
      background: #9696321a;
    
    }

    .mob-cen { 
      
      text-align: center; 
      &__lefter { left: -$apx-m-20; }

    }

  }

}