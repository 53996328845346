@import "../../../styles/apx.scss";
.payment-contract {

  padding-left: $apx-420;

  .mt-ul { margin-top: -$apx-100; }

  &__img {
    
    width: $apx-1360;

    &__yocheck {
    
      width: $apx-1200;
      margin: 0 auto;
    
    }
  
  }

}

@media screen and ( max-width: 960px ) {

  .payment-contract {

    padding-left: unset;

    .mt-ul { margin-top: $apx-m-25; }

    &__img { width: $apx-m-305; }

    ul {
    
      margin-left: -$apx-m-25;

      li {
      
        font-size: $apx-m-13;
        line-height: $apx-m-15;
        margin-top: $apx-m-5;
        margin-bottom: $apx-m-10;
      
      }
    
    }

  }

}