@import "../../../styles/apx.scss";
.textpage {

  padding-left: $apx-420;

  ul { left: $apx-21; }
  .text-28 { left: $apx-5; }

  &__add-point { width: $apx-500; left: -$apx-100; }
  &__first { width: $apx-1400; left: -$apx-30; }
  &__second { width: $apx-1400; }
  &__third { width: $apx-1500; left: -$apx-180; }
  &__fourth { width: $apx-1350; }
  &__fifth { width: $apx-1350; }

  &__button { margin-top: $apx-100; }

}

@media screen and ( max-width: 960px ) {
  
  .textpage {

    padding-left: unset;

    .font-medium { line-height: $apx-m-18; left: -$apx-m-25; }

    &__ul {
    
      width: 120%;
      left: -$apx-m-35;
      padding: $apx-m-22 0 $apx-m-11;
      background: #77b4ff0c;
    
    }

    ul li { left: $apx-m-20;  }

    ol li { left: -$apx-m-20;  }

    &__add-point { width: $apx-m-300; }

    .mob-cen { 
      
      left: $apx-m-10;
      text-align: center;
      &__lefter { left: -$apx-m-25; }

    }

    &__liner {
    
      opacity: 0.03;
      width: 120%;
      left: -$apx-m-22;
      height: $apx-m-2;
      background: #000000;
    
    }

    &__img { width: $apx-m-305; }

  }

}