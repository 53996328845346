@import "../../../styles/apx.scss";
.customization {

  padding-left: $apx-420;

  .set-grey_img {

    width: $apx-128;
    height: $apx-110;

  }

  .set-orange_img {

    width: $apx-536;
    height: $apx-218;

  }

  .button_img {

    width: $apx-528;
    height: $apx-88;
    margin-top: $apx-43;
    margin-left: -$apx-18;

  }

  .set-week_img {

    width: $apx-475;
    height: $apx-588;

  }

  .set-week {

    width: $apx-1363;
    height: $apx-928;

  }

  .set_day {

    width: $apx-1363;
    height: $apx-928;
    margin-left: $apx-25;

  }

  .set {

    width: $apx-1388;
    height: $apx-750;

  }

  .arrow { min-width: $apx-55; }
  
}

@media screen and ( max-width: 960px ) {
  .customization {

    padding-left: unset;

    &__row { 
      
      &.items-center { align-items: unset; }

      &.mt-28 { margin-top: $apx-m-15; }
      &.mt-40 { margin-top: $apx-m-21 !important;}
      
    }

    .row_block { margin-top: unset; }
  
    .button_img {

      width: $apx-m-300;
      height: $apx-m-56;
      margin-top: $apx-m-16;
      margin-left: -$apx-m-10;

    }

    .set-orange_img {

      width: $apx-m-300;
      height: $apx-m-138;
      margin-top: $apx-m-11;
      margin-left: -$apx-m-5;
  
    }

    .set-week_img {

      height: auto;
      width: $apx-m-300;

    }

    .set_week {

      height: auto;
      width: $apx-m-300;
      margin-left: $apx-m-3;

    }

    .set-grey_img {

      width: $apx-m-121 ;
      height: $apx-m-110 ;
      margin-top: $apx-m-13;

    }

    .mobil_set_day {

      width: $apx-m-300;
      height: $apx-m-100;

    }

    .set-mobil {

      height: auto;
      width: $apx-m-300;

    }
    
    .mt-28 { margin-top: $apx-m-18; }
    .mt-43 { margin-top: $apx-m-26; }

    .ml-26 { margin-left: unset !important; } 

    .tip_container {

      svg {
      
        min-width: $apx-m-65;
        min-height: $apx-m-65;
      
      }

      div { width: 100%; }
    
    }

  }

}