@import "../../../styles/apx.scss";

.container {

  width: 100%;

  &__menu { width: $apx-424; }

  &__content {

    padding-top: $apx-34;
    padding-left: $apx-58;
    padding-right: $apx-53;
    padding-bottom: $apx-54;
    
  }

}

@media screen and ( max-width: 960px ) {  

  .container {

    margin-top: $apx-m-80;

    &__menu { width: unset; }

    &__content {
  
      max-width: 100%;
      padding-top: $apx-m-20;
      padding-left: $apx-m-28;
      padding-right: $apx-m-22;
      padding-bottom: $apx-m-20;
      
    }
  
  }

}