@import "../../../styles/apx.scss";
.addslider { 

  ul { 
    
    left: -$apx-20; 
    li { line-height: $apx-30; }

  }

  &__main { width: $apx-1350; }

}

@media screen and ( max-width: 960px ) {
  
  .addslider {

    .text-16-m { line-height: $apx-m-20; }

    ul { 
      
      left: -$apx-m-16;
      li { line-height: $apx-m-20; }
    
    }

    &__main { min-width: $apx-m-360; left: -$apx-m-30 ;}

    &__main { width: $apx-m-300; margin-left: $apx-m-5; }
    &__warn { width: $apx-m-320; margin-left: -$apx-m-5; }

    &__button { margin: $apx-m-25 0; }

  }

}