@import "../../../styles/apx.scss";
.shopopen {

  // padding-left: $apx-20;

  .text-23 { line-height: $apx-35; height: $apx-50; }

  .fontbottom { top: $apx-640; }

  .textdown { top: -$apx-480; }
  .textup { margin-top: -$apx-850; }

  &__first { width: $apx-460; }
  &__second { width: $apx-450; }
  &__third { width: $apx-440; }
  &__fourth { width: $apx-440; }

  &__button { margin-top: $apx-100; }

}

@media screen and ( max-width: 960px ) {

  .shopopen {
  
    .text-23 { line-height: $apx-m-20; height: unset; }
  
    .fontbottom { top: unset; }
  
    .textdown { top: unset; }
    .textup { margin-top: unset; }
  
    &__first { width: $apx-m-300; }
    &__second { width: $apx-m-300; }
    &__third { width: $apx-m-300; margin-bottom: -$apx-m-30; }
    &__fourth { width: $apx-440; }

    .ml-60 { margin-left: unset; }
  
    &__button { margin-top: $apx-100; }
  
  }

}