@import "../../../styles/apx.scss";
.button {

  width: $apx-663;
  height: $apx-74;
  color: #FFFF;
  font-weight: 700;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $apx-21;

  &.blue { background: linear-gradient(92.05deg, #50BCDE 11.01%, #64C4FA 99.24%), #C4C4C4; }
    
  &.orange { background: linear-gradient(92.05deg, #FFB356 11.01%, #F6841F 99.24%), #C4C4C4; }

}

@media screen and ( max-width: 960px ) { 

  .button {

    width: $apx-m-300;
    height: $apx-m-62;
    border-radius: $apx-m-10;
    white-space: pre-wrap;

    &.ml-26 { margin-left: unset; margin-right: unset; }
    
  }

}