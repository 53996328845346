@import "../../../styles/apx.scss";
.interface {

  &__main {

    margin: 0 auto;
    margin-top: $apx-88;
    width: $apx-1250;

  }

}

@media screen and ( max-width: 960px ) {
  .interface {

    &__main {

      width: $apx-m-310;
      margin-top: $apx-m-60;

    }

    .text-23 {

      font-size: $apx-m-15;
      line-height: $apx-m-18;

    }

  }

}