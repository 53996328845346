@import "../../../styles/apx.scss";
.faq {

  &__main { width: $apx-1200; }

}

@media screen and ( max-width: 960px ) {
  
  .faq {

    .text-16-m { line-height: $apx-m-20; }

    &__main {
      
      left: -$apx-m-30 ;
      min-width: $apx-m-360;
    
    }

  }

}