@import "../../../styles/apx.scss";
.map {

  padding-left: $apx-420;

  &__main { width: $apx-1400; }

}

@media screen and ( max-width: 960px ) {
  .map {

    padding-left: unset;

    .text-15-m { line-height: $apx-m-18; }

    &__main {
      
      left: -$apx-m-30 ;
      min-width: $apx-m-360;
    
    }

    ul li { 
      
      left: $apx-m-4;
      display: list-item !important;
    
    }

  }

}