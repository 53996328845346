@import "../../../styles/apx.scss";
.handleclient {

  &__freetime { width: $apx-950; }

  &__sheet { width: $apx-1200; }

  &__addtime {

    width: $apx-1200;
    margin-left: -$apx-50;

  }

}

@media screen and ( max-width: 960px ) {

  .handleclient {

    &__freetime { width: $apx-m-305; }

    &__clock { width: $apx-m-305; }
  
    &__sheet { width: $apx-m-305; }
  
    &__addtime {
  
      width: $apx-m-305;
      margin-left: unset;
  
    }
  
  }

}