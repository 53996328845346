@import "../../../styles/apx.scss";
.timegap {

  &__time {

    z-index: 5;
    width: $apx-1300;

    }

  &__sheet, &__without { width: $apx-1300; }

}

@media screen and ( max-width: 960px ) {
  .timegap {

    &__time { width: $apx-m-310; }
    &__clock,
    &__during,
    &__done,
    &__cancel { width: $apx-m-305; }
  
  }

}