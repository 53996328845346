 @import "../../../styles/apx.scss";
.adding_service {

  &__row {

    padding-left: $apx-16; 

    svg {

      width: $apx-53;
      margin-left: $apx-32;
      margin-right: $apx-26;
      
    }

    img {

      width: $apx-120;
      height: $apx-110;

    }

  }

  .guide.desktop { 
    
    width: $apx-1373;
    height: $apx-1036;

  }

}

@media screen and ( max-width: 960px ) {  
  .adding_service {

    &__row {

      &.mt-24, &.mt-40 {

        margin-top: unset; 
        padding-left: unset;

      }
  
      img {
  
        width: $apx-m-118;
        height: $apx-m-110;
        margin-top: $apx-m-10;
        margin-bottom: $apx-m-14;
        margin-left: $apx-m-88;
  
      }
  
    }

  
    .guide.mobile { 
      
      margin: auto;
      width: $apx-m-300;
      margin-top: $apx-m-18;
  
    }

    .items-center { align-items: unset; }
  
  }  

}