/*! ------ AdaptivePX.scss v1.0 by Rikky: https://adaptivepx.com | All rights reserved (c) ------ */ 


  $apx-1: 0.052083vw;
  $apx-2: 0.104166vw;
  $apx-3: 0.156249vw;
  $apx-4: 0.208332vw;
  $apx-5: 0.260415vw;
  $apx-6: 0.312498vw;
  $apx-7: 0.364581vw;
  $apx-8: 0.416664vw;
  $apx-9: 0.468747vw;
  $apx-10: 0.52083vw;
  $apx-11: 0.572913vw;
  $apx-12: 0.624996vw;
  $apx-13: 0.677079vw;
  $apx-14: 0.729162vw;
  $apx-15: 0.781245vw;
  $apx-16: 0.833328vw;
  $apx-17: 0.885411vw;
  $apx-18: 0.937494vw;
  $apx-19: 0.989577vw;
  $apx-20: 1.04166vw;
  $apx-21: 1.093743vw;
  $apx-22: 1.145826vw;
  $apx-23: 1.197909vw;
  $apx-24: 1.249992vw;
  $apx-25: 1.302075vw;
  $apx-26: 1.354158vw;
  $apx-27: 1.406241vw;
  $apx-28: 1.458324vw;
  $apx-29: 1.510407vw;
  $apx-30: 1.56249vw;
  $apx-31: 1.614573vw;
  $apx-32: 1.666656vw;
  $apx-33: 1.718739vw;
  $apx-34: 1.770822vw;
  $apx-35: 1.822905vw;
  $apx-36: 1.874988vw;
  $apx-37: 1.927071vw;
  $apx-38: 1.979154vw;
  $apx-39: 2.031237vw;
  $apx-40: 2.08332vw;
  $apx-41: 2.135403vw;
  $apx-42: 2.187486vw;
  $apx-43: 2.239569vw;
  $apx-44: 2.291652vw;
  $apx-45: 2.343735vw;
  $apx-46: 2.395818vw;
  $apx-47: 2.447901vw;
  $apx-48: 2.499984vw;
  $apx-49: 2.552067vw;
  $apx-50: 2.60415vw;
  $apx-51: 2.656233vw;
  $apx-52: 2.708316vw;
  $apx-53: 2.760399vw;
  $apx-54: 2.812482vw;
  $apx-55: 2.864565vw;
  $apx-56: 2.916648vw;
  $apx-57: 2.968731vw;
  $apx-58: 3.020814vw;
  $apx-59: 3.072897vw;
  $apx-60: 3.12498vw;
  $apx-61: 3.177063vw;
  $apx-62: 3.229146vw;
  $apx-63: 3.281229vw;
  $apx-64: 3.333312vw;
  $apx-65: 3.385395vw;
  $apx-66: 3.437478vw;
  $apx-67: 3.489561vw;
  $apx-68: 3.541644vw;
  $apx-69: 3.593727vw;
  $apx-70: 3.64581vw;
  $apx-71: 3.697893vw;
  $apx-72: 3.749976vw;
  $apx-73: 3.802059vw;
  $apx-74: 3.854142vw;
  $apx-75: 3.906225vw;
  $apx-76: 3.958308vw;
  $apx-77: 4.010391vw;
  $apx-78: 4.062474vw;
  $apx-79: 4.114557vw;
  $apx-80: 4.16664vw;
  $apx-81: 4.218723vw;
  $apx-82: 4.270806vw;
  $apx-83: 4.322889vw;
  $apx-84: 4.374972vw;
  $apx-85: 4.427055vw;
  $apx-86: 4.479138vw;
  $apx-87: 4.531221vw;
  $apx-88: 4.583304vw;
  $apx-89: 4.635387vw;
  $apx-90: 4.68747vw;
  $apx-91: 4.739553vw;
  $apx-92: 4.791636vw;
  $apx-93: 4.843719vw;
  $apx-94: 4.895802vw;
  $apx-95: 4.947885vw;
  $apx-96: 4.999968vw;
  $apx-97: 5.052051vw;
  $apx-98: 5.104134vw;
  $apx-99: 5.156217vw;
  $apx-100: 5.2083vw;
  $apx-101: 5.260383vw;
  $apx-102: 5.312466vw;
  $apx-103: 5.364549vw;
  $apx-104: 5.416632vw;
  $apx-105: 5.468715vw;
  $apx-106: 5.520798vw;
  $apx-107: 5.572881vw;
  $apx-108: 5.624964vw;
  $apx-109: 5.677047vw;
  $apx-110: 5.72913vw;
  $apx-111: 5.781213vw;
  $apx-112: 5.833296vw;
  $apx-113: 5.885379vw;
  $apx-114: 5.937462vw;
  $apx-115: 5.989545vw;
  $apx-116: 6.041628vw;
  $apx-117: 6.093711vw;
  $apx-118: 6.145794vw;
  $apx-119: 6.197877vw;
  $apx-120: 6.24996vw;
  $apx-121: 6.302043vw;
  $apx-122: 6.354126vw;
  $apx-123: 6.406209vw;
  $apx-124: 6.458292vw;
  $apx-125: 6.510375vw;
  $apx-126: 6.562458vw;
  $apx-127: 6.614541vw;
  $apx-128: 6.666624vw;
  $apx-129: 6.718707vw;
  $apx-130: 6.77079vw;
  $apx-131: 6.822873vw;
  $apx-132: 6.874956vw;
  $apx-133: 6.927039vw;
  $apx-134: 6.979122vw;
  $apx-135: 7.031205vw;
  $apx-136: 7.083288vw;
  $apx-137: 7.135371vw;
  $apx-138: 7.187454vw;
  $apx-139: 7.239537vw;
  $apx-140: 7.29162vw;
  $apx-141: 7.343703vw;
  $apx-142: 7.395786vw;
  $apx-143: 7.447869vw;
  $apx-144: 7.499952vw;
  $apx-145: 7.552035vw;
  $apx-146: 7.604118vw;
  $apx-147: 7.656201vw;
  $apx-148: 7.708284vw;
  $apx-149: 7.760367vw;
  $apx-150: 7.81245vw;
  $apx-151: 7.864533vw;
  $apx-152: 7.916616vw;
  $apx-153: 7.968699vw;
  $apx-154: 8.020782vw;
  $apx-155: 8.072865vw;
  $apx-156: 8.124948vw;
  $apx-157: 8.177031vw;
  $apx-158: 8.229114vw;
  $apx-159: 8.281197vw;
  $apx-160: 8.33328vw;
  $apx-161: 8.385363vw;
  $apx-162: 8.437446vw;
  $apx-163: 8.489529vw;
  $apx-164: 8.541612vw;
  $apx-165: 8.593695vw;
  $apx-166: 8.645778vw;
  $apx-167: 8.697861vw;
  $apx-168: 8.749944vw;
  $apx-169: 8.802027vw;
  $apx-170: 8.85411vw;
  $apx-171: 8.906193vw;
  $apx-172: 8.958276vw;
  $apx-173: 9.010359vw;
  $apx-174: 9.062442vw;
  $apx-175: 9.114525vw;
  $apx-176: 9.166608vw;
  $apx-177: 9.218691vw;
  $apx-178: 9.270774vw;
  $apx-179: 9.322857vw;
  $apx-180: 9.37494vw;
  $apx-181: 9.427023vw;
  $apx-182: 9.479106vw;
  $apx-183: 9.531189vw;
  $apx-184: 9.583272vw;
  $apx-185: 9.635355vw;
  $apx-186: 9.687438vw;
  $apx-187: 9.739521vw;
  $apx-188: 9.791604vw;
  $apx-189: 9.843687vw;
  $apx-190: 9.89577vw;
  $apx-191: 9.947853vw;
  $apx-192: 9.999936vw;
  $apx-193: 10.052019vw;
  $apx-194: 10.104102vw;
  $apx-195: 10.156185vw;
  $apx-196: 10.208268vw;
  $apx-197: 10.260351vw;
  $apx-198: 10.312434vw;
  $apx-199: 10.364517vw;
  $apx-200: 10.4166vw;
  $apx-201: 10.468683vw;
  $apx-202: 10.520766vw;
  $apx-203: 10.572849vw;
  $apx-204: 10.624932vw;
  $apx-205: 10.677015vw;
  $apx-206: 10.729098vw;
  $apx-207: 10.781181vw;
  $apx-208: 10.833264vw;
  $apx-209: 10.885347vw;
  $apx-210: 10.93743vw;
  $apx-211: 10.989513vw;
  $apx-212: 11.041596vw;
  $apx-213: 11.093679vw;
  $apx-214: 11.145762vw;
  $apx-215: 11.197845vw;
  $apx-216: 11.249928vw;
  $apx-217: 11.302011vw;
  $apx-218: 11.354094vw;
  $apx-219: 11.406177vw;
  $apx-220: 11.45826vw;
  $apx-221: 11.510343vw;
  $apx-222: 11.562426vw;
  $apx-223: 11.614509vw;
  $apx-224: 11.666592vw;
  $apx-225: 11.718675vw;
  $apx-226: 11.770758vw;
  $apx-227: 11.822841vw;
  $apx-228: 11.874924vw;
  $apx-229: 11.927007vw;
  $apx-230: 11.97909vw;
  $apx-231: 12.031173vw;
  $apx-232: 12.083256vw;
  $apx-233: 12.135339vw;
  $apx-234: 12.187422vw;
  $apx-235: 12.239505vw;
  $apx-236: 12.291588vw;
  $apx-237: 12.343671vw;
  $apx-238: 12.395754vw;
  $apx-239: 12.447837vw;
  $apx-240: 12.49992vw;
  $apx-241: 12.552003vw;
  $apx-242: 12.604086vw;
  $apx-243: 12.656169vw;
  $apx-244: 12.708252vw;
  $apx-245: 12.760335vw;
  $apx-246: 12.812418vw;
  $apx-247: 12.864501vw;
  $apx-248: 12.916584vw;
  $apx-249: 12.968667vw;
  $apx-250: 13.02075vw;
  $apx-251: 13.072833vw;
  $apx-252: 13.124916vw;
  $apx-253: 13.176999vw;
  $apx-254: 13.229082vw;
  $apx-255: 13.281165vw;
  $apx-256: 13.333248vw;
  $apx-257: 13.385331vw;
  $apx-258: 13.437414vw;
  $apx-259: 13.489497vw;
  $apx-260: 13.54158vw;
  $apx-261: 13.593663vw;
  $apx-262: 13.645746vw;
  $apx-263: 13.697829vw;
  $apx-264: 13.749912vw;
  $apx-265: 13.801995vw;
  $apx-266: 13.854078vw;
  $apx-267: 13.906161vw;
  $apx-268: 13.958244vw;
  $apx-269: 14.010327vw;
  $apx-270: 14.06241vw;
  $apx-271: 14.114493vw;
  $apx-272: 14.166576vw;
  $apx-273: 14.218659vw;
  $apx-274: 14.270742vw;
  $apx-275: 14.322825vw;
  $apx-276: 14.374908vw;
  $apx-277: 14.426991vw;
  $apx-278: 14.479074vw;
  $apx-279: 14.531157vw;
  $apx-280: 14.58324vw;
  $apx-281: 14.635323vw;
  $apx-282: 14.687406vw;
  $apx-283: 14.739489vw;
  $apx-284: 14.791572vw;
  $apx-285: 14.843655vw;
  $apx-286: 14.895738vw;
  $apx-287: 14.947821vw;
  $apx-288: 14.999904vw;
  $apx-289: 15.051987vw;
  $apx-290: 15.10407vw;
  $apx-291: 15.156153vw;
  $apx-292: 15.208236vw;
  $apx-293: 15.260319vw;
  $apx-294: 15.312402vw;
  $apx-295: 15.364485vw;
  $apx-296: 15.416568vw;
  $apx-297: 15.468651vw;
  $apx-298: 15.520734vw;
  $apx-299: 15.572817vw;
  $apx-300: 15.6249vw;
  $apx-301: 15.676983vw;
  $apx-302: 15.729066vw;
  $apx-303: 15.781149vw;
  $apx-304: 15.833232vw;
  $apx-305: 15.885315vw;
  $apx-306: 15.937398vw;
  $apx-307: 15.989481vw;
  $apx-308: 16.041564vw;
  $apx-309: 16.093647vw;
  $apx-310: 16.14573vw;
  $apx-311: 16.197813vw;
  $apx-312: 16.249896vw;
  $apx-313: 16.301979vw;
  $apx-314: 16.354062vw;
  $apx-315: 16.406145vw;
  $apx-316: 16.458228vw;
  $apx-317: 16.510311vw;
  $apx-318: 16.562394vw;
  $apx-319: 16.614477vw;
  $apx-320: 16.66656vw;
  $apx-321: 16.718643vw;
  $apx-322: 16.770726vw;
  $apx-323: 16.822809vw;
  $apx-324: 16.874892vw;
  $apx-325: 16.926975vw;
  $apx-326: 16.979058vw;
  $apx-327: 17.031141vw;
  $apx-328: 17.083224vw;
  $apx-329: 17.135307vw;
  $apx-330: 17.18739vw;
  $apx-331: 17.239473vw;
  $apx-332: 17.291556vw;
  $apx-333: 17.343639vw;
  $apx-334: 17.395722vw;
  $apx-335: 17.447805vw;
  $apx-336: 17.499888vw;
  $apx-337: 17.551971vw;
  $apx-338: 17.604054vw;
  $apx-339: 17.656137vw;
  $apx-340: 17.70822vw;
  $apx-341: 17.760303vw;
  $apx-342: 17.812386vw;
  $apx-343: 17.864469vw;
  $apx-344: 17.916552vw;
  $apx-345: 17.968635vw;
  $apx-346: 18.020718vw;
  $apx-347: 18.072801vw;
  $apx-348: 18.124884vw;
  $apx-349: 18.176967vw;
  $apx-350: 18.22905vw;
  $apx-351: 18.281133vw;
  $apx-352: 18.333216vw;
  $apx-353: 18.385299vw;
  $apx-354: 18.437382vw;
  $apx-355: 18.489465vw;
  $apx-356: 18.541548vw;
  $apx-357: 18.593631vw;
  $apx-358: 18.645714vw;
  $apx-359: 18.697797vw;
  $apx-360: 18.74988vw;
  $apx-361: 18.801963vw;
  $apx-362: 18.854046vw;
  $apx-363: 18.906129vw;
  $apx-364: 18.958212vw;
  $apx-365: 19.010295vw;
  $apx-366: 19.062378vw;
  $apx-367: 19.114461vw;
  $apx-368: 19.166544vw;
  $apx-369: 19.218627vw;
  $apx-370: 19.27071vw;
  $apx-371: 19.322793vw;
  $apx-372: 19.374876vw;
  $apx-373: 19.426959vw;
  $apx-374: 19.479042vw;
  $apx-375: 19.531125vw;
  $apx-376: 19.583208vw;
  $apx-377: 19.635291vw;
  $apx-378: 19.687374vw;
  $apx-379: 19.739457vw;
  $apx-380: 19.79154vw;
  $apx-381: 19.843623vw;
  $apx-382: 19.895706vw;
  $apx-383: 19.947789vw;
  $apx-384: 19.999872vw;
  $apx-385: 20.051955vw;
  $apx-386: 20.104038vw;
  $apx-387: 20.156121vw;
  $apx-388: 20.208204vw;
  $apx-389: 20.260287vw;
  $apx-390: 20.31237vw;
  $apx-391: 20.364453vw;
  $apx-392: 20.416536vw;
  $apx-393: 20.468619vw;
  $apx-394: 20.520702vw;
  $apx-395: 20.572785vw;
  $apx-396: 20.624868vw;
  $apx-397: 20.676951vw;
  $apx-398: 20.729034vw;
  $apx-399: 20.781117vw;
  $apx-400: 20.8332vw;
  $apx-401: 20.885283vw;
  $apx-402: 20.937366vw;
  $apx-403: 20.989449vw;
  $apx-404: 21.041532vw;
  $apx-405: 21.093615vw;
  $apx-406: 21.145698vw;
  $apx-407: 21.197781vw;
  $apx-408: 21.249864vw;
  $apx-409: 21.301947vw;
  $apx-410: 21.35403vw;
  $apx-411: 21.406113vw;
  $apx-412: 21.458196vw;
  $apx-413: 21.510279vw;
  $apx-414: 21.562362vw;
  $apx-415: 21.614445vw;
  $apx-416: 21.666528vw;
  $apx-417: 21.718611vw;
  $apx-418: 21.770694vw;
  $apx-419: 21.822777vw;
  $apx-420: 21.87486vw;
  $apx-421: 21.926943vw;
  $apx-422: 21.979026vw;
  $apx-423: 22.031109vw;
  $apx-424: 22.083192vw;
  $apx-425: 22.135275vw;
  $apx-426: 22.187358vw;
  $apx-427: 22.239441vw;
  $apx-428: 22.291524vw;
  $apx-429: 22.343607vw;
  $apx-430: 22.39569vw;
  $apx-431: 22.447773vw;
  $apx-432: 22.499856vw;
  $apx-433: 22.551939vw;
  $apx-434: 22.604022vw;
  $apx-435: 22.656105vw;
  $apx-436: 22.708188vw;
  $apx-437: 22.760271vw;
  $apx-438: 22.812354vw;
  $apx-439: 22.864437vw;
  $apx-440: 22.91652vw;
  $apx-441: 22.968603vw;
  $apx-442: 23.020686vw;
  $apx-443: 23.072769vw;
  $apx-444: 23.124852vw;
  $apx-445: 23.176935vw;
  $apx-446: 23.229018vw;
  $apx-447: 23.281101vw;
  $apx-448: 23.333184vw;
  $apx-449: 23.385267vw;
  $apx-450: 23.43735vw;
  $apx-451: 23.489433vw;
  $apx-452: 23.541516vw;
  $apx-453: 23.593599vw;
  $apx-454: 23.645682vw;
  $apx-455: 23.697765vw;
  $apx-456: 23.749848vw;
  $apx-457: 23.801931vw;
  $apx-458: 23.854014vw;
  $apx-459: 23.906097vw;
  $apx-460: 23.95818vw;
  $apx-461: 24.010263vw;
  $apx-462: 24.062346vw;
  $apx-463: 24.114429vw;
  $apx-464: 24.166512vw;
  $apx-465: 24.218595vw;
  $apx-466: 24.270678vw;
  $apx-467: 24.322761vw;
  $apx-468: 24.374844vw;
  $apx-469: 24.426927vw;
  $apx-470: 24.47901vw;
  $apx-471: 24.531093vw;
  $apx-472: 24.583176vw;
  $apx-473: 24.635259vw;
  $apx-474: 24.687342vw;
  $apx-475: 24.739425vw;
  $apx-476: 24.791508vw;
  $apx-477: 24.843591vw;
  $apx-478: 24.895674vw;
  $apx-479: 24.947757vw;
  $apx-480: 24.99984vw;
  $apx-481: 25.051923vw;
  $apx-482: 25.104006vw;
  $apx-483: 25.156089vw;
  $apx-484: 25.208172vw;
  $apx-485: 25.260255vw;
  $apx-486: 25.312338vw;
  $apx-487: 25.364421vw;
  $apx-488: 25.416504vw;
  $apx-489: 25.468587vw;
  $apx-490: 25.52067vw;
  $apx-491: 25.572753vw;
  $apx-492: 25.624836vw;
  $apx-493: 25.676919vw;
  $apx-494: 25.729002vw;
  $apx-495: 25.781085vw;
  $apx-496: 25.833168vw;
  $apx-497: 25.885251vw;
  $apx-498: 25.937334vw;
  $apx-499: 25.989417vw;
  $apx-500: 26.0415vw;
  $apx-501: 26.093583vw;
  $apx-502: 26.145666vw;
  $apx-503: 26.197749vw;
  $apx-504: 26.249832vw;
  $apx-505: 26.301915vw;
  $apx-506: 26.353998vw;
  $apx-507: 26.406081vw;
  $apx-508: 26.458164vw;
  $apx-509: 26.510247vw;
  $apx-510: 26.56233vw;
  $apx-511: 26.614413vw;
  $apx-512: 26.666496vw;
  $apx-513: 26.718579vw;
  $apx-514: 26.770662vw;
  $apx-515: 26.822745vw;
  $apx-516: 26.874828vw;
  $apx-517: 26.926911vw;
  $apx-518: 26.978994vw;
  $apx-519: 27.031077vw;
  $apx-520: 27.08316vw;
  $apx-521: 27.135243vw;
  $apx-522: 27.187326vw;
  $apx-523: 27.239409vw;
  $apx-524: 27.291492vw;
  $apx-525: 27.343575vw;
  $apx-526: 27.395658vw;
  $apx-527: 27.447741vw;
  $apx-528: 27.499824vw;
  $apx-529: 27.551907vw;
  $apx-530: 27.60399vw;
  $apx-531: 27.656073vw;
  $apx-532: 27.708156vw;
  $apx-533: 27.760239vw;
  $apx-534: 27.812322vw;
  $apx-535: 27.864405vw;
  $apx-536: 27.916488vw;
  $apx-537: 27.968571vw;
  $apx-538: 28.020654vw;
  $apx-539: 28.072737vw;
  $apx-540: 28.12482vw;
  $apx-541: 28.176903vw;
  $apx-542: 28.228986vw;
  $apx-543: 28.281069vw;
  $apx-544: 28.333152vw;
  $apx-545: 28.385235vw;
  $apx-546: 28.437318vw;
  $apx-547: 28.489401vw;
  $apx-548: 28.541484vw;
  $apx-549: 28.593567vw;
  $apx-550: 28.64565vw;
  $apx-551: 28.697733vw;
  $apx-552: 28.749816vw;
  $apx-553: 28.801899vw;
  $apx-554: 28.853982vw;
  $apx-555: 28.906065vw;
  $apx-556: 28.958148vw;
  $apx-557: 29.010231vw;
  $apx-558: 29.062314vw;
  $apx-559: 29.114397vw;
  $apx-560: 29.16648vw;
  $apx-561: 29.218563vw;
  $apx-562: 29.270646vw;
  $apx-563: 29.322729vw;
  $apx-564: 29.374812vw;
  $apx-565: 29.426895vw;
  $apx-566: 29.478978vw;
  $apx-567: 29.531061vw;
  $apx-568: 29.583144vw;
  $apx-569: 29.635227vw;
  $apx-570: 29.68731vw;
  $apx-571: 29.739393vw;
  $apx-572: 29.791476vw;
  $apx-573: 29.843559vw;
  $apx-574: 29.895642vw;
  $apx-575: 29.947725vw;
  $apx-576: 29.999808vw;
  $apx-577: 30.051891vw;
  $apx-578: 30.103974vw;
  $apx-579: 30.156057vw;
  $apx-580: 30.20814vw;
  $apx-581: 30.260223vw;
  $apx-582: 30.312306vw;
  $apx-583: 30.364389vw;
  $apx-584: 30.416472vw;
  $apx-585: 30.468555vw;
  $apx-586: 30.520638vw;
  $apx-587: 30.572721vw;
  $apx-588: 30.624804vw;
  $apx-589: 30.676887vw;
  $apx-590: 30.72897vw;
  $apx-591: 30.781053vw;
  $apx-592: 30.833136vw;
  $apx-593: 30.885219vw;
  $apx-594: 30.937302vw;
  $apx-595: 30.989385vw;
  $apx-596: 31.041468vw;
  $apx-597: 31.093551vw;
  $apx-598: 31.145634vw;
  $apx-599: 31.197717vw;
  $apx-600: 31.2498vw;
  $apx-601: 31.301883vw;
  $apx-602: 31.353966vw;
  $apx-603: 31.406049vw;
  $apx-604: 31.458132vw;
  $apx-605: 31.510215vw;
  $apx-606: 31.562298vw;
  $apx-607: 31.614381vw;
  $apx-608: 31.666464vw;
  $apx-609: 31.718547vw;
  $apx-610: 31.77063vw;
  $apx-611: 31.822713vw;
  $apx-612: 31.874796vw;
  $apx-613: 31.926879vw;
  $apx-614: 31.978962vw;
  $apx-615: 32.031045vw;
  $apx-616: 32.083128vw;
  $apx-617: 32.135211vw;
  $apx-618: 32.187294vw;
  $apx-619: 32.239377vw;
  $apx-620: 32.29146vw;
  $apx-621: 32.343543vw;
  $apx-622: 32.395626vw;
  $apx-623: 32.447709vw;
  $apx-624: 32.499792vw;
  $apx-625: 32.551875vw;
  $apx-626: 32.603958vw;
  $apx-627: 32.656041vw;
  $apx-628: 32.708124vw;
  $apx-629: 32.760207vw;
  $apx-630: 32.81229vw;
  $apx-631: 32.864373vw;
  $apx-632: 32.916456vw;
  $apx-633: 32.968539vw;
  $apx-634: 33.020622vw;
  $apx-635: 33.072705vw;
  $apx-636: 33.124788vw;
  $apx-637: 33.176871vw;
  $apx-638: 33.228954vw;
  $apx-639: 33.281037vw;
  $apx-640: 33.33312vw;
  $apx-641: 33.385203vw;
  $apx-642: 33.437286vw;
  $apx-643: 33.489369vw;
  $apx-644: 33.541452vw;
  $apx-645: 33.593535vw;
  $apx-646: 33.645618vw;
  $apx-647: 33.697701vw;
  $apx-648: 33.749784vw;
  $apx-649: 33.801867vw;
  $apx-650: 33.85395vw;
  $apx-651: 33.906033vw;
  $apx-652: 33.958116vw;
  $apx-653: 34.010199vw;
  $apx-654: 34.062282vw;
  $apx-655: 34.114365vw;
  $apx-656: 34.166448vw;
  $apx-657: 34.218531vw;
  $apx-658: 34.270614vw;
  $apx-659: 34.322697vw;
  $apx-660: 34.37478vw;
  $apx-661: 34.426863vw;
  $apx-662: 34.478946vw;
  $apx-663: 34.531029vw;
  $apx-664: 34.583112vw;
  $apx-665: 34.635195vw;
  $apx-666: 34.687278vw;
  $apx-667: 34.739361vw;
  $apx-668: 34.791444vw;
  $apx-669: 34.843527vw;
  $apx-670: 34.89561vw;
  $apx-671: 34.947693vw;
  $apx-672: 34.999776vw;
  $apx-673: 35.051859vw;
  $apx-674: 35.103942vw;
  $apx-675: 35.156025vw;
  $apx-676: 35.208108vw;
  $apx-677: 35.260191vw;
  $apx-678: 35.312274vw;
  $apx-679: 35.364357vw;
  $apx-680: 35.41644vw;
  $apx-681: 35.468523vw;
  $apx-682: 35.520606vw;
  $apx-683: 35.572689vw;
  $apx-684: 35.624772vw;
  $apx-685: 35.676855vw;
  $apx-686: 35.728938vw;
  $apx-687: 35.781021vw;
  $apx-688: 35.833104vw;
  $apx-689: 35.885187vw;
  $apx-690: 35.93727vw;
  $apx-691: 35.989353vw;
  $apx-692: 36.041436vw;
  $apx-693: 36.093519vw;
  $apx-694: 36.145602vw;
  $apx-695: 36.197685vw;
  $apx-696: 36.249768vw;
  $apx-697: 36.301851vw;
  $apx-698: 36.353934vw;
  $apx-699: 36.406017vw;
  $apx-700: 36.4581vw;
  $apx-701: 36.510183vw;
  $apx-702: 36.562266vw;
  $apx-703: 36.614349vw;
  $apx-704: 36.666432vw;
  $apx-705: 36.718515vw;
  $apx-706: 36.770598vw;
  $apx-707: 36.822681vw;
  $apx-708: 36.874764vw;
  $apx-709: 36.926847vw;
  $apx-710: 36.97893vw;
  $apx-711: 37.031013vw;
  $apx-712: 37.083096vw;
  $apx-713: 37.135179vw;
  $apx-714: 37.187262vw;
  $apx-715: 37.239345vw;
  $apx-716: 37.291428vw;
  $apx-717: 37.343511vw;
  $apx-718: 37.395594vw;
  $apx-719: 37.447677vw;
  $apx-720: 37.49976vw;
  $apx-721: 37.551843vw;
  $apx-722: 37.603926vw;
  $apx-723: 37.656009vw;
  $apx-724: 37.708092vw;
  $apx-725: 37.760175vw;
  $apx-726: 37.812258vw;
  $apx-727: 37.864341vw;
  $apx-728: 37.916424vw;
  $apx-729: 37.968507vw;
  $apx-730: 38.02059vw;
  $apx-731: 38.072673vw;
  $apx-732: 38.124756vw;
  $apx-733: 38.176839vw;
  $apx-734: 38.228922vw;
  $apx-735: 38.281005vw;
  $apx-736: 38.333088vw;
  $apx-737: 38.385171vw;
  $apx-738: 38.437254vw;
  $apx-739: 38.489337vw;
  $apx-740: 38.54142vw;
  $apx-741: 38.593503vw;
  $apx-742: 38.645586vw;
  $apx-743: 38.697669vw;
  $apx-744: 38.749752vw;
  $apx-745: 38.801835vw;
  $apx-746: 38.853918vw;
  $apx-747: 38.906001vw;
  $apx-748: 38.958084vw;
  $apx-749: 39.010167vw;
  $apx-750: 39.06225vw;
  $apx-751: 39.114333vw;
  $apx-752: 39.166416vw;
  $apx-753: 39.218499vw;
  $apx-754: 39.270582vw;
  $apx-755: 39.322665vw;
  $apx-756: 39.374748vw;
  $apx-757: 39.426831vw;
  $apx-758: 39.478914vw;
  $apx-759: 39.530997vw;
  $apx-760: 39.58308vw;
  $apx-761: 39.635163vw;
  $apx-762: 39.687246vw;
  $apx-763: 39.739329vw;
  $apx-764: 39.791412vw;
  $apx-765: 39.843495vw;
  $apx-766: 39.895578vw;
  $apx-767: 39.947661vw;
  $apx-768: 39.999744vw;
  $apx-769: 40.051827vw;
  $apx-770: 40.10391vw;
  $apx-771: 40.155993vw;
  $apx-772: 40.208076vw;
  $apx-773: 40.260159vw;
  $apx-774: 40.312242vw;
  $apx-775: 40.364325vw;
  $apx-776: 40.416408vw;
  $apx-777: 40.468491vw;
  $apx-778: 40.520574vw;
  $apx-779: 40.572657vw;
  $apx-780: 40.62474vw;
  $apx-781: 40.676823vw;
  $apx-782: 40.728906vw;
  $apx-783: 40.780989vw;
  $apx-784: 40.833072vw;
  $apx-785: 40.885155vw;
  $apx-786: 40.937238vw;
  $apx-787: 40.989321vw;
  $apx-788: 41.041404vw;
  $apx-789: 41.093487vw;
  $apx-790: 41.14557vw;
  $apx-791: 41.197653vw;
  $apx-792: 41.249736vw;
  $apx-793: 41.301819vw;
  $apx-794: 41.353902vw;
  $apx-795: 41.405985vw;
  $apx-796: 41.458068vw;
  $apx-797: 41.510151vw;
  $apx-798: 41.562234vw;
  $apx-799: 41.614317vw;
  $apx-800: 41.6664vw;
  $apx-801: 41.718483vw;
  $apx-802: 41.770566vw;
  $apx-803: 41.822649vw;
  $apx-804: 41.874732vw;
  $apx-805: 41.926815vw;
  $apx-806: 41.978898vw;
  $apx-807: 42.030981vw;
  $apx-808: 42.083064vw;
  $apx-809: 42.135147vw;
  $apx-810: 42.18723vw;
  $apx-811: 42.239313vw;
  $apx-812: 42.291396vw;
  $apx-813: 42.343479vw;
  $apx-814: 42.395562vw;
  $apx-815: 42.447645vw;
  $apx-816: 42.499728vw;
  $apx-817: 42.551811vw;
  $apx-818: 42.603894vw;
  $apx-819: 42.655977vw;
  $apx-820: 42.70806vw;
  $apx-821: 42.760143vw;
  $apx-822: 42.812226vw;
  $apx-823: 42.864309vw;
  $apx-824: 42.916392vw;
  $apx-825: 42.968475vw;
  $apx-826: 43.020558vw;
  $apx-827: 43.072641vw;
  $apx-828: 43.124724vw;
  $apx-829: 43.176807vw;
  $apx-830: 43.22889vw;
  $apx-831: 43.280973vw;
  $apx-832: 43.333056vw;
  $apx-833: 43.385139vw;
  $apx-834: 43.437222vw;
  $apx-835: 43.489305vw;
  $apx-836: 43.541388vw;
  $apx-837: 43.593471vw;
  $apx-838: 43.645554vw;
  $apx-839: 43.697637vw;
  $apx-840: 43.74972vw;
  $apx-841: 43.801803vw;
  $apx-842: 43.853886vw;
  $apx-843: 43.905969vw;
  $apx-844: 43.958052vw;
  $apx-845: 44.010135vw;
  $apx-846: 44.062218vw;
  $apx-847: 44.114301vw;
  $apx-848: 44.166384vw;
  $apx-849: 44.218467vw;
  $apx-850: 44.27055vw;
  $apx-851: 44.322633vw;
  $apx-852: 44.374716vw;
  $apx-853: 44.426799vw;
  $apx-854: 44.478882vw;
  $apx-855: 44.530965vw;
  $apx-856: 44.583048vw;
  $apx-857: 44.635131vw;
  $apx-858: 44.687214vw;
  $apx-859: 44.739297vw;
  $apx-860: 44.79138vw;
  $apx-861: 44.843463vw;
  $apx-862: 44.895546vw;
  $apx-863: 44.947629vw;
  $apx-864: 44.999712vw;
  $apx-865: 45.051795vw;
  $apx-866: 45.103878vw;
  $apx-867: 45.155961vw;
  $apx-868: 45.208044vw;
  $apx-869: 45.260127vw;
  $apx-870: 45.31221vw;
  $apx-871: 45.364293vw;
  $apx-872: 45.416376vw;
  $apx-873: 45.468459vw;
  $apx-874: 45.520542vw;
  $apx-875: 45.572625vw;
  $apx-876: 45.624708vw;
  $apx-877: 45.676791vw;
  $apx-878: 45.728874vw;
  $apx-879: 45.780957vw;
  $apx-880: 45.83304vw;
  $apx-881: 45.885123vw;
  $apx-882: 45.937206vw;
  $apx-883: 45.989289vw;
  $apx-884: 46.041372vw;
  $apx-885: 46.093455vw;
  $apx-886: 46.145538vw;
  $apx-887: 46.197621vw;
  $apx-888: 46.249704vw;
  $apx-889: 46.301787vw;
  $apx-890: 46.35387vw;
  $apx-891: 46.405953vw;
  $apx-892: 46.458036vw;
  $apx-893: 46.510119vw;
  $apx-894: 46.562202vw;
  $apx-895: 46.614285vw;
  $apx-896: 46.666368vw;
  $apx-897: 46.718451vw;
  $apx-898: 46.770534vw;
  $apx-899: 46.822617vw;
  $apx-900: 46.8747vw;
  $apx-901: 46.926783vw;
  $apx-902: 46.978866vw;
  $apx-903: 47.030949vw;
  $apx-904: 47.083032vw;
  $apx-905: 47.135115vw;
  $apx-906: 47.187198vw;
  $apx-907: 47.239281vw;
  $apx-908: 47.291364vw;
  $apx-909: 47.343447vw;
  $apx-910: 47.39553vw;
  $apx-911: 47.447613vw;
  $apx-912: 47.499696vw;
  $apx-913: 47.551779vw;
  $apx-914: 47.603862vw;
  $apx-915: 47.655945vw;
  $apx-916: 47.708028vw;
  $apx-917: 47.760111vw;
  $apx-918: 47.812194vw;
  $apx-919: 47.864277vw;
  $apx-920: 47.91636vw;
  $apx-921: 47.968443vw;
  $apx-922: 48.020526vw;
  $apx-923: 48.072609vw;
  $apx-924: 48.124692vw;
  $apx-925: 48.176775vw;
  $apx-926: 48.228858vw;
  $apx-927: 48.280941vw;
  $apx-928: 48.333024vw;
  $apx-929: 48.385107vw;
  $apx-930: 48.43719vw;
  $apx-931: 48.489273vw;
  $apx-932: 48.541356vw;
  $apx-933: 48.593439vw;
  $apx-934: 48.645522vw;
  $apx-935: 48.697605vw;
  $apx-936: 48.749688vw;
  $apx-937: 48.801771vw;
  $apx-938: 48.853854vw;
  $apx-939: 48.905937vw;
  $apx-940: 48.95802vw;
  $apx-941: 49.010103vw;
  $apx-942: 49.062186vw;
  $apx-943: 49.114269vw;
  $apx-944: 49.166352vw;
  $apx-945: 49.218435vw;
  $apx-946: 49.270518vw;
  $apx-947: 49.322601vw;
  $apx-948: 49.374684vw;
  $apx-949: 49.426767vw;
  $apx-950: 49.47885vw;
  $apx-951: 49.530933vw;
  $apx-952: 49.583016vw;
  $apx-953: 49.635099vw;
  $apx-954: 49.687182vw;
  $apx-955: 49.739265vw;
  $apx-956: 49.791348vw;
  $apx-957: 49.843431vw;
  $apx-958: 49.895514vw;
  $apx-959: 49.947597vw;
  $apx-960: 49.99968vw;
  $apx-961: 50.051763vw;
  $apx-962: 50.103846vw;
  $apx-963: 50.155929vw;
  $apx-964: 50.208012vw;
  $apx-965: 50.260095vw;
  $apx-966: 50.312178vw;
  $apx-967: 50.364261vw;
  $apx-968: 50.416344vw;
  $apx-969: 50.468427vw;
  $apx-970: 50.52051vw;
  $apx-971: 50.572593vw;
  $apx-972: 50.624676vw;
  $apx-973: 50.676759vw;
  $apx-974: 50.728842vw;
  $apx-975: 50.780925vw;
  $apx-976: 50.833008vw;
  $apx-977: 50.885091vw;
  $apx-978: 50.937174vw;
  $apx-979: 50.989257vw;
  $apx-980: 51.04134vw;
  $apx-981: 51.093423vw;
  $apx-982: 51.145506vw;
  $apx-983: 51.197589vw;
  $apx-984: 51.249672vw;
  $apx-985: 51.301755vw;
  $apx-986: 51.353838vw;
  $apx-987: 51.405921vw;
  $apx-988: 51.458004vw;
  $apx-989: 51.510087vw;
  $apx-990: 51.56217vw;
  $apx-991: 51.614253vw;
  $apx-992: 51.666336vw;
  $apx-993: 51.718419vw;
  $apx-994: 51.770502vw;
  $apx-995: 51.822585vw;
  $apx-996: 51.874668vw;
  $apx-997: 51.926751vw;
  $apx-998: 51.978834vw;
  $apx-999: 52.030917vw;
  $apx-1000: 52.083vw;
  $apx-1001: 52.135083vw;
  $apx-1002: 52.187166vw;
  $apx-1003: 52.239249vw;
  $apx-1004: 52.291332vw;
  $apx-1005: 52.343415vw;
  $apx-1006: 52.395498vw;
  $apx-1007: 52.447581vw;
  $apx-1008: 52.499664vw;
  $apx-1009: 52.551747vw;
  $apx-1010: 52.60383vw;
  $apx-1011: 52.655913vw;
  $apx-1012: 52.707996vw;
  $apx-1013: 52.760079vw;
  $apx-1014: 52.812162vw;
  $apx-1015: 52.864245vw;
  $apx-1016: 52.916328vw;
  $apx-1017: 52.968411vw;
  $apx-1018: 53.020494vw;
  $apx-1019: 53.072577vw;
  $apx-1020: 53.12466vw;
  $apx-1021: 53.176743vw;
  $apx-1022: 53.228826vw;
  $apx-1023: 53.280909vw;
  $apx-1024: 53.332992vw;
  $apx-1025: 53.385075vw;
  $apx-1026: 53.437158vw;
  $apx-1027: 53.489241vw;
  $apx-1028: 53.541324vw;
  $apx-1029: 53.593407vw;
  $apx-1030: 53.64549vw;
  $apx-1031: 53.697573vw;
  $apx-1032: 53.749656vw;
  $apx-1033: 53.801739vw;
  $apx-1034: 53.853822vw;
  $apx-1035: 53.905905vw;
  $apx-1036: 53.957988vw;
  $apx-1037: 54.010071vw;
  $apx-1038: 54.062154vw;
  $apx-1039: 54.114237vw;
  $apx-1040: 54.16632vw;
  $apx-1041: 54.218403vw;
  $apx-1042: 54.270486vw;
  $apx-1043: 54.322569vw;
  $apx-1044: 54.374652vw;
  $apx-1045: 54.426735vw;
  $apx-1046: 54.478818vw;
  $apx-1047: 54.530901vw;
  $apx-1048: 54.582984vw;
  $apx-1049: 54.635067vw;
  $apx-1050: 54.68715vw;
  $apx-1051: 54.739233vw;
  $apx-1052: 54.791316vw;
  $apx-1053: 54.843399vw;
  $apx-1054: 54.895482vw;
  $apx-1055: 54.947565vw;
  $apx-1056: 54.999648vw;
  $apx-1057: 55.051731vw;
  $apx-1058: 55.103814vw;
  $apx-1059: 55.155897vw;
  $apx-1060: 55.20798vw;
  $apx-1061: 55.260063vw;
  $apx-1062: 55.312146vw;
  $apx-1063: 55.364229vw;
  $apx-1064: 55.416312vw;
  $apx-1065: 55.468395vw;
  $apx-1066: 55.520478vw;
  $apx-1067: 55.572561vw;
  $apx-1068: 55.624644vw;
  $apx-1069: 55.676727vw;
  $apx-1070: 55.72881vw;
  $apx-1071: 55.780893vw;
  $apx-1072: 55.832976vw;
  $apx-1073: 55.885059vw;
  $apx-1074: 55.937142vw;
  $apx-1075: 55.989225vw;
  $apx-1076: 56.041308vw;
  $apx-1077: 56.093391vw;
  $apx-1078: 56.145474vw;
  $apx-1079: 56.197557vw;
  $apx-1080: 56.24964vw;
  $apx-1081: 56.301723vw;
  $apx-1082: 56.353806vw;
  $apx-1083: 56.405889vw;
  $apx-1084: 56.457972vw;
  $apx-1085: 56.510055vw;
  $apx-1086: 56.562138vw;
  $apx-1087: 56.614221vw;
  $apx-1088: 56.666304vw;
  $apx-1089: 56.718387vw;
  $apx-1090: 56.77047vw;
  $apx-1091: 56.822553vw;
  $apx-1092: 56.874636vw;
  $apx-1093: 56.926719vw;
  $apx-1094: 56.978802vw;
  $apx-1095: 57.030885vw;
  $apx-1096: 57.082968vw;
  $apx-1097: 57.135051vw;
  $apx-1098: 57.187134vw;
  $apx-1099: 57.239217vw;
  $apx-1100: 57.2913vw;
  $apx-1101: 57.343383vw;
  $apx-1102: 57.395466vw;
  $apx-1103: 57.447549vw;
  $apx-1104: 57.499632vw;
  $apx-1105: 57.551715vw;
  $apx-1106: 57.603798vw;
  $apx-1107: 57.655881vw;
  $apx-1108: 57.707964vw;
  $apx-1109: 57.760047vw;
  $apx-1110: 57.81213vw;
  $apx-1111: 57.864213vw;
  $apx-1112: 57.916296vw;
  $apx-1113: 57.968379vw;
  $apx-1114: 58.020462vw;
  $apx-1115: 58.072545vw;
  $apx-1116: 58.124628vw;
  $apx-1117: 58.176711vw;
  $apx-1118: 58.228794vw;
  $apx-1119: 58.280877vw;
  $apx-1120: 58.33296vw;
  $apx-1121: 58.385043vw;
  $apx-1122: 58.437126vw;
  $apx-1123: 58.489209vw;
  $apx-1124: 58.541292vw;
  $apx-1125: 58.593375vw;
  $apx-1126: 58.645458vw;
  $apx-1127: 58.697541vw;
  $apx-1128: 58.749624vw;
  $apx-1129: 58.801707vw;
  $apx-1130: 58.85379vw;
  $apx-1131: 58.905873vw;
  $apx-1132: 58.957956vw;
  $apx-1133: 59.010039vw;
  $apx-1134: 59.062122vw;
  $apx-1135: 59.114205vw;
  $apx-1136: 59.166288vw;
  $apx-1137: 59.218371vw;
  $apx-1138: 59.270454vw;
  $apx-1139: 59.322537vw;
  $apx-1140: 59.37462vw;
  $apx-1141: 59.426703vw;
  $apx-1142: 59.478786vw;
  $apx-1143: 59.530869vw;
  $apx-1144: 59.582952vw;
  $apx-1145: 59.635035vw;
  $apx-1146: 59.687118vw;
  $apx-1147: 59.739201vw;
  $apx-1148: 59.791284vw;
  $apx-1149: 59.843367vw;
  $apx-1150: 59.89545vw;
  $apx-1151: 59.947533vw;
  $apx-1152: 59.999616vw;
  $apx-1153: 60.051699vw;
  $apx-1154: 60.103782vw;
  $apx-1155: 60.155865vw;
  $apx-1156: 60.207948vw;
  $apx-1157: 60.260031vw;
  $apx-1158: 60.312114vw;
  $apx-1159: 60.364197vw;
  $apx-1160: 60.41628vw;
  $apx-1161: 60.468363vw;
  $apx-1162: 60.520446vw;
  $apx-1163: 60.572529vw;
  $apx-1164: 60.624612vw;
  $apx-1165: 60.676695vw;
  $apx-1166: 60.728778vw;
  $apx-1167: 60.780861vw;
  $apx-1168: 60.832944vw;
  $apx-1169: 60.885027vw;
  $apx-1170: 60.93711vw;
  $apx-1171: 60.989193vw;
  $apx-1172: 61.041276vw;
  $apx-1173: 61.093359vw;
  $apx-1174: 61.145442vw;
  $apx-1175: 61.197525vw;
  $apx-1176: 61.249608vw;
  $apx-1177: 61.301691vw;
  $apx-1178: 61.353774vw;
  $apx-1179: 61.405857vw;
  $apx-1180: 61.45794vw;
  $apx-1181: 61.510023vw;
  $apx-1182: 61.562106vw;
  $apx-1183: 61.614189vw;
  $apx-1184: 61.666272vw;
  $apx-1185: 61.718355vw;
  $apx-1186: 61.770438vw;
  $apx-1187: 61.822521vw;
  $apx-1188: 61.874604vw;
  $apx-1189: 61.926687vw;
  $apx-1190: 61.97877vw;
  $apx-1191: 62.030853vw;
  $apx-1192: 62.082936vw;
  $apx-1193: 62.135019vw;
  $apx-1194: 62.187102vw;
  $apx-1195: 62.239185vw;
  $apx-1196: 62.291268vw;
  $apx-1197: 62.343351vw;
  $apx-1198: 62.395434vw;
  $apx-1199: 62.447517vw;
  $apx-1200: 62.4996vw;
  $apx-1201: 62.551683vw;
  $apx-1202: 62.603766vw;
  $apx-1203: 62.655849vw;
  $apx-1204: 62.707932vw;
  $apx-1205: 62.760015vw;
  $apx-1206: 62.812098vw;
  $apx-1207: 62.864181vw;
  $apx-1208: 62.916264vw;
  $apx-1209: 62.968347vw;
  $apx-1210: 63.02043vw;
  $apx-1211: 63.072513vw;
  $apx-1212: 63.124596vw;
  $apx-1213: 63.176679vw;
  $apx-1214: 63.228762vw;
  $apx-1215: 63.280845vw;
  $apx-1216: 63.332928vw;
  $apx-1217: 63.385011vw;
  $apx-1218: 63.437094vw;
  $apx-1219: 63.489177vw;
  $apx-1220: 63.54126vw;
  $apx-1221: 63.593343vw;
  $apx-1222: 63.645426vw;
  $apx-1223: 63.697509vw;
  $apx-1224: 63.749592vw;
  $apx-1225: 63.801675vw;
  $apx-1226: 63.853758vw;
  $apx-1227: 63.905841vw;
  $apx-1228: 63.957924vw;
  $apx-1229: 64.010007vw;
  $apx-1230: 64.06209vw;
  $apx-1231: 64.114173vw;
  $apx-1232: 64.166256vw;
  $apx-1233: 64.218339vw;
  $apx-1234: 64.270422vw;
  $apx-1235: 64.322505vw;
  $apx-1236: 64.374588vw;
  $apx-1237: 64.426671vw;
  $apx-1238: 64.478754vw;
  $apx-1239: 64.530837vw;
  $apx-1240: 64.58292vw;
  $apx-1241: 64.635003vw;
  $apx-1242: 64.687086vw;
  $apx-1243: 64.739169vw;
  $apx-1244: 64.791252vw;
  $apx-1245: 64.843335vw;
  $apx-1246: 64.895418vw;
  $apx-1247: 64.947501vw;
  $apx-1248: 64.999584vw;
  $apx-1249: 65.051667vw;
  $apx-1250: 65.10375vw;
  $apx-1251: 65.155833vw;
  $apx-1252: 65.207916vw;
  $apx-1253: 65.259999vw;
  $apx-1254: 65.312082vw;
  $apx-1255: 65.364165vw;
  $apx-1256: 65.416248vw;
  $apx-1257: 65.468331vw;
  $apx-1258: 65.520414vw;
  $apx-1259: 65.572497vw;
  $apx-1260: 65.62458vw;
  $apx-1261: 65.676663vw;
  $apx-1262: 65.728746vw;
  $apx-1263: 65.780829vw;
  $apx-1264: 65.832912vw;
  $apx-1265: 65.884995vw;
  $apx-1266: 65.937078vw;
  $apx-1267: 65.989161vw;
  $apx-1268: 66.041244vw;
  $apx-1269: 66.093327vw;
  $apx-1270: 66.14541vw;
  $apx-1271: 66.197493vw;
  $apx-1272: 66.249576vw;
  $apx-1273: 66.301659vw;
  $apx-1274: 66.353742vw;
  $apx-1275: 66.405825vw;
  $apx-1276: 66.457908vw;
  $apx-1277: 66.509991vw;
  $apx-1278: 66.562074vw;
  $apx-1279: 66.614157vw;
  $apx-1280: 66.66624vw;
  $apx-1281: 66.718323vw;
  $apx-1282: 66.770406vw;
  $apx-1283: 66.822489vw;
  $apx-1284: 66.874572vw;
  $apx-1285: 66.926655vw;
  $apx-1286: 66.978738vw;
  $apx-1287: 67.030821vw;
  $apx-1288: 67.082904vw;
  $apx-1289: 67.134987vw;
  $apx-1290: 67.18707vw;
  $apx-1291: 67.239153vw;
  $apx-1292: 67.291236vw;
  $apx-1293: 67.343319vw;
  $apx-1294: 67.395402vw;
  $apx-1295: 67.447485vw;
  $apx-1296: 67.499568vw;
  $apx-1297: 67.551651vw;
  $apx-1298: 67.603734vw;
  $apx-1299: 67.655817vw;
  $apx-1300: 67.7079vw;
  $apx-1301: 67.759983vw;
  $apx-1302: 67.812066vw;
  $apx-1303: 67.864149vw;
  $apx-1304: 67.916232vw;
  $apx-1305: 67.968315vw;
  $apx-1306: 68.020398vw;
  $apx-1307: 68.072481vw;
  $apx-1308: 68.124564vw;
  $apx-1309: 68.176647vw;
  $apx-1310: 68.22873vw;
  $apx-1311: 68.280813vw;
  $apx-1312: 68.332896vw;
  $apx-1313: 68.384979vw;
  $apx-1314: 68.437062vw;
  $apx-1315: 68.489145vw;
  $apx-1316: 68.541228vw;
  $apx-1317: 68.593311vw;
  $apx-1318: 68.645394vw;
  $apx-1319: 68.697477vw;
  $apx-1320: 68.74956vw;
  $apx-1321: 68.801643vw;
  $apx-1322: 68.853726vw;
  $apx-1323: 68.905809vw;
  $apx-1324: 68.957892vw;
  $apx-1325: 69.009975vw;
  $apx-1326: 69.062058vw;
  $apx-1327: 69.114141vw;
  $apx-1328: 69.166224vw;
  $apx-1329: 69.218307vw;
  $apx-1330: 69.27039vw;
  $apx-1331: 69.322473vw;
  $apx-1332: 69.374556vw;
  $apx-1333: 69.426639vw;
  $apx-1334: 69.478722vw;
  $apx-1335: 69.530805vw;
  $apx-1336: 69.582888vw;
  $apx-1337: 69.634971vw;
  $apx-1338: 69.687054vw;
  $apx-1339: 69.739137vw;
  $apx-1340: 69.79122vw;
  $apx-1341: 69.843303vw;
  $apx-1342: 69.895386vw;
  $apx-1343: 69.947469vw;
  $apx-1344: 69.999552vw;
  $apx-1345: 70.051635vw;
  $apx-1346: 70.103718vw;
  $apx-1347: 70.155801vw;
  $apx-1348: 70.207884vw;
  $apx-1349: 70.259967vw;
  $apx-1350: 70.31205vw;
  $apx-1351: 70.364133vw;
  $apx-1352: 70.416216vw;
  $apx-1353: 70.468299vw;
  $apx-1354: 70.520382vw;
  $apx-1355: 70.572465vw;
  $apx-1356: 70.624548vw;
  $apx-1357: 70.676631vw;
  $apx-1358: 70.728714vw;
  $apx-1359: 70.780797vw;
  $apx-1360: 70.83288vw;
  $apx-1361: 70.884963vw;
  $apx-1362: 70.937046vw;
  $apx-1363: 70.989129vw;
  $apx-1364: 71.041212vw;
  $apx-1365: 71.093295vw;
  $apx-1366: 71.145378vw;
  $apx-1367: 71.197461vw;
  $apx-1368: 71.249544vw;
  $apx-1369: 71.301627vw;
  $apx-1370: 71.35371vw;
  $apx-1371: 71.405793vw;
  $apx-1372: 71.457876vw;
  $apx-1373: 71.509959vw;
  $apx-1374: 71.562042vw;
  $apx-1375: 71.614125vw;
  $apx-1376: 71.666208vw;
  $apx-1377: 71.718291vw;
  $apx-1378: 71.770374vw;
  $apx-1379: 71.822457vw;
  $apx-1380: 71.87454vw;
  $apx-1381: 71.926623vw;
  $apx-1382: 71.978706vw;
  $apx-1383: 72.030789vw;
  $apx-1384: 72.082872vw;
  $apx-1385: 72.134955vw;
  $apx-1386: 72.187038vw;
  $apx-1387: 72.239121vw;
  $apx-1388: 72.291204vw;
  $apx-1389: 72.343287vw;
  $apx-1390: 72.39537vw;
  $apx-1391: 72.447453vw;
  $apx-1392: 72.499536vw;
  $apx-1393: 72.551619vw;
  $apx-1394: 72.603702vw;
  $apx-1395: 72.655785vw;
  $apx-1396: 72.707868vw;
  $apx-1397: 72.759951vw;
  $apx-1398: 72.812034vw;
  $apx-1399: 72.864117vw;
  $apx-1400: 72.9162vw;
  $apx-1401: 72.968283vw;
  $apx-1402: 73.020366vw;
  $apx-1403: 73.072449vw;
  $apx-1404: 73.124532vw;
  $apx-1405: 73.176615vw;
  $apx-1406: 73.228698vw;
  $apx-1407: 73.280781vw;
  $apx-1408: 73.332864vw;
  $apx-1409: 73.384947vw;
  $apx-1410: 73.43703vw;
  $apx-1411: 73.489113vw;
  $apx-1412: 73.541196vw;
  $apx-1413: 73.593279vw;
  $apx-1414: 73.645362vw;
  $apx-1415: 73.697445vw;
  $apx-1416: 73.749528vw;
  $apx-1417: 73.801611vw;
  $apx-1418: 73.853694vw;
  $apx-1419: 73.905777vw;
  $apx-1420: 73.95786vw;
  $apx-1421: 74.009943vw;
  $apx-1422: 74.062026vw;
  $apx-1423: 74.114109vw;
  $apx-1424: 74.166192vw;
  $apx-1425: 74.218275vw;
  $apx-1426: 74.270358vw;
  $apx-1427: 74.322441vw;
  $apx-1428: 74.374524vw;
  $apx-1429: 74.426607vw;
  $apx-1430: 74.47869vw;
  $apx-1431: 74.530773vw;
  $apx-1432: 74.582856vw;
  $apx-1433: 74.634939vw;
  $apx-1434: 74.687022vw;
  $apx-1435: 74.739105vw;
  $apx-1436: 74.791188vw;
  $apx-1437: 74.843271vw;
  $apx-1438: 74.895354vw;
  $apx-1439: 74.947437vw;
  $apx-1440: 74.99952vw;
  $apx-1441: 75.051603vw;
  $apx-1442: 75.103686vw;
  $apx-1443: 75.155769vw;
  $apx-1444: 75.207852vw;
  $apx-1445: 75.259935vw;
  $apx-1446: 75.312018vw;
  $apx-1447: 75.364101vw;
  $apx-1448: 75.416184vw;
  $apx-1449: 75.468267vw;
  $apx-1450: 75.52035vw;
  $apx-1451: 75.572433vw;
  $apx-1452: 75.624516vw;
  $apx-1453: 75.676599vw;
  $apx-1454: 75.728682vw;
  $apx-1455: 75.780765vw;
  $apx-1456: 75.832848vw;
  $apx-1457: 75.884931vw;
  $apx-1458: 75.937014vw;
  $apx-1459: 75.989097vw;
  $apx-1460: 76.04118vw;
  $apx-1461: 76.093263vw;
  $apx-1462: 76.145346vw;
  $apx-1463: 76.197429vw;
  $apx-1464: 76.249512vw;
  $apx-1465: 76.301595vw;
  $apx-1466: 76.353678vw;
  $apx-1467: 76.405761vw;
  $apx-1468: 76.457844vw;
  $apx-1469: 76.509927vw;
  $apx-1470: 76.56201vw;
  $apx-1471: 76.614093vw;
  $apx-1472: 76.666176vw;
  $apx-1473: 76.718259vw;
  $apx-1474: 76.770342vw;
  $apx-1475: 76.822425vw;
  $apx-1476: 76.874508vw;
  $apx-1477: 76.926591vw;
  $apx-1478: 76.978674vw;
  $apx-1479: 77.030757vw;
  $apx-1480: 77.08284vw;
  $apx-1481: 77.134923vw;
  $apx-1482: 77.187006vw;
  $apx-1483: 77.239089vw;
  $apx-1484: 77.291172vw;
  $apx-1485: 77.343255vw;
  $apx-1486: 77.395338vw;
  $apx-1487: 77.447421vw;
  $apx-1488: 77.499504vw;
  $apx-1489: 77.551587vw;
  $apx-1490: 77.60367vw;
  $apx-1491: 77.655753vw;
  $apx-1492: 77.707836vw;
  $apx-1493: 77.759919vw;
  $apx-1494: 77.812002vw;
  $apx-1495: 77.864085vw;
  $apx-1496: 77.916168vw;
  $apx-1497: 77.968251vw;
  $apx-1498: 78.020334vw;
  $apx-1499: 78.072417vw;
  $apx-1500: 78.1245vw;
  $apx-1501: 78.176583vw;
  $apx-1502: 78.228666vw;
  $apx-1503: 78.280749vw;
  $apx-1504: 78.332832vw;
  $apx-1505: 78.384915vw;
  $apx-1506: 78.436998vw;
  $apx-1507: 78.489081vw;
  $apx-1508: 78.541164vw;
  $apx-1509: 78.593247vw;
  $apx-1510: 78.64533vw;
  $apx-1511: 78.697413vw;
  $apx-1512: 78.749496vw;
  $apx-1513: 78.801579vw;
  $apx-1514: 78.853662vw;
  $apx-1515: 78.905745vw;
  $apx-1516: 78.957828vw;
  $apx-1517: 79.009911vw;
  $apx-1518: 79.061994vw;
  $apx-1519: 79.114077vw;
  $apx-1520: 79.16616vw;
  $apx-1521: 79.218243vw;
  $apx-1522: 79.270326vw;
  $apx-1523: 79.322409vw;
  $apx-1524: 79.374492vw;
  $apx-1525: 79.426575vw;
  $apx-1526: 79.478658vw;
  $apx-1527: 79.530741vw;
  $apx-1528: 79.582824vw;
  $apx-1529: 79.634907vw;
  $apx-1530: 79.68699vw;
  $apx-1531: 79.739073vw;
  $apx-1532: 79.791156vw;
  $apx-1533: 79.843239vw;
  $apx-1534: 79.895322vw;
  $apx-1535: 79.947405vw;
  $apx-1536: 79.999488vw;
  $apx-1537: 80.051571vw;
  $apx-1538: 80.103654vw;
  $apx-1539: 80.155737vw;
  $apx-1540: 80.20782vw;
  $apx-1541: 80.259903vw;
  $apx-1542: 80.311986vw;
  $apx-1543: 80.364069vw;
  $apx-1544: 80.416152vw;
  $apx-1545: 80.468235vw;
  $apx-1546: 80.520318vw;
  $apx-1547: 80.572401vw;
  $apx-1548: 80.624484vw;
  $apx-1549: 80.676567vw;
  $apx-1550: 80.72865vw;
  $apx-1551: 80.780733vw;
  $apx-1552: 80.832816vw;
  $apx-1553: 80.884899vw;
  $apx-1554: 80.936982vw;
  $apx-1555: 80.989065vw;
  $apx-1556: 81.041148vw;
  $apx-1557: 81.093231vw;
  $apx-1558: 81.145314vw;
  $apx-1559: 81.197397vw;
  $apx-1560: 81.24948vw;
  $apx-1561: 81.301563vw;
  $apx-1562: 81.353646vw;
  $apx-1563: 81.405729vw;
  $apx-1564: 81.457812vw;
  $apx-1565: 81.509895vw;
  $apx-1566: 81.561978vw;
  $apx-1567: 81.614061vw;
  $apx-1568: 81.666144vw;
  $apx-1569: 81.718227vw;
  $apx-1570: 81.77031vw;
  $apx-1571: 81.822393vw;
  $apx-1572: 81.874476vw;
  $apx-1573: 81.926559vw;
  $apx-1574: 81.978642vw;
  $apx-1575: 82.030725vw;
  $apx-1576: 82.082808vw;
  $apx-1577: 82.134891vw;
  $apx-1578: 82.186974vw;
  $apx-1579: 82.239057vw;
  $apx-1580: 82.29114vw;
  $apx-1581: 82.343223vw;
  $apx-1582: 82.395306vw;
  $apx-1583: 82.447389vw;
  $apx-1584: 82.499472vw;
  $apx-1585: 82.551555vw;
  $apx-1586: 82.603638vw;
  $apx-1587: 82.655721vw;
  $apx-1588: 82.707804vw;
  $apx-1589: 82.759887vw;
  $apx-1590: 82.81197vw;
  $apx-1591: 82.864053vw;
  $apx-1592: 82.916136vw;
  $apx-1593: 82.968219vw;
  $apx-1594: 83.020302vw;
  $apx-1595: 83.072385vw;
  $apx-1596: 83.124468vw;
  $apx-1597: 83.176551vw;
  $apx-1598: 83.228634vw;
  $apx-1599: 83.280717vw;
  $apx-1600: 83.3328vw;
  $apx-1601: 83.384883vw;
  $apx-1602: 83.436966vw;
  $apx-1603: 83.489049vw;
  $apx-1604: 83.541132vw;
  $apx-1605: 83.593215vw;
  $apx-1606: 83.645298vw;
  $apx-1607: 83.697381vw;
  $apx-1608: 83.749464vw;
  $apx-1609: 83.801547vw;
  $apx-1610: 83.85363vw;
  $apx-1611: 83.905713vw;
  $apx-1612: 83.957796vw;
  $apx-1613: 84.009879vw;
  $apx-1614: 84.061962vw;
  $apx-1615: 84.114045vw;
  $apx-1616: 84.166128vw;
  $apx-1617: 84.218211vw;
  $apx-1618: 84.270294vw;
  $apx-1619: 84.322377vw;
  $apx-1620: 84.37446vw;
  $apx-1621: 84.426543vw;
  $apx-1622: 84.478626vw;
  $apx-1623: 84.530709vw;
  $apx-1624: 84.582792vw;
  $apx-1625: 84.634875vw;
  $apx-1626: 84.686958vw;
  $apx-1627: 84.739041vw;
  $apx-1628: 84.791124vw;
  $apx-1629: 84.843207vw;
  $apx-1630: 84.89529vw;
  $apx-1631: 84.947373vw;
  $apx-1632: 84.999456vw;
  $apx-1633: 85.051539vw;
  $apx-1634: 85.103622vw;
  $apx-1635: 85.155705vw;
  $apx-1636: 85.207788vw;
  $apx-1637: 85.259871vw;
  $apx-1638: 85.311954vw;
  $apx-1639: 85.364037vw;
  $apx-1640: 85.41612vw;
  $apx-1641: 85.468203vw;
  $apx-1642: 85.520286vw;
  $apx-1643: 85.572369vw;
  $apx-1644: 85.624452vw;
  $apx-1645: 85.676535vw;
  $apx-1646: 85.728618vw;
  $apx-1647: 85.780701vw;
  $apx-1648: 85.832784vw;
  $apx-1649: 85.884867vw;
  $apx-1650: 85.93695vw;
  $apx-1651: 85.989033vw;
  $apx-1652: 86.041116vw;
  $apx-1653: 86.093199vw;
  $apx-1654: 86.145282vw;
  $apx-1655: 86.197365vw;
  $apx-1656: 86.249448vw;
  $apx-1657: 86.301531vw;
  $apx-1658: 86.353614vw;
  $apx-1659: 86.405697vw;
  $apx-1660: 86.45778vw;
  $apx-1661: 86.509863vw;
  $apx-1662: 86.561946vw;
  $apx-1663: 86.614029vw;
  $apx-1664: 86.666112vw;
  $apx-1665: 86.718195vw;
  $apx-1666: 86.770278vw;
  $apx-1667: 86.822361vw;
  $apx-1668: 86.874444vw;
  $apx-1669: 86.926527vw;
  $apx-1670: 86.97861vw;
  $apx-1671: 87.030693vw;
  $apx-1672: 87.082776vw;
  $apx-1673: 87.134859vw;
  $apx-1674: 87.186942vw;
  $apx-1675: 87.239025vw;
  $apx-1676: 87.291108vw;
  $apx-1677: 87.343191vw;
  $apx-1678: 87.395274vw;
  $apx-1679: 87.447357vw;
  $apx-1680: 87.49944vw;
  $apx-1681: 87.551523vw;
  $apx-1682: 87.603606vw;
  $apx-1683: 87.655689vw;
  $apx-1684: 87.707772vw;
  $apx-1685: 87.759855vw;
  $apx-1686: 87.811938vw;
  $apx-1687: 87.864021vw;
  $apx-1688: 87.916104vw;
  $apx-1689: 87.968187vw;
  $apx-1690: 88.02027vw;
  $apx-1691: 88.072353vw;
  $apx-1692: 88.124436vw;
  $apx-1693: 88.176519vw;
  $apx-1694: 88.228602vw;
  $apx-1695: 88.280685vw;
  $apx-1696: 88.332768vw;
  $apx-1697: 88.384851vw;
  $apx-1698: 88.436934vw;
  $apx-1699: 88.489017vw;
  $apx-1700: 88.5411vw;
  $apx-1701: 88.593183vw;
  $apx-1702: 88.645266vw;
  $apx-1703: 88.697349vw;
  $apx-1704: 88.749432vw;
  $apx-1705: 88.801515vw;
  $apx-1706: 88.853598vw;
  $apx-1707: 88.905681vw;
  $apx-1708: 88.957764vw;
  $apx-1709: 89.009847vw;
  $apx-1710: 89.06193vw;
  $apx-1711: 89.114013vw;
  $apx-1712: 89.166096vw;
  $apx-1713: 89.218179vw;
  $apx-1714: 89.270262vw;
  $apx-1715: 89.322345vw;
  $apx-1716: 89.374428vw;
  $apx-1717: 89.426511vw;
  $apx-1718: 89.478594vw;
  $apx-1719: 89.530677vw;
  $apx-1720: 89.58276vw;
  $apx-1721: 89.634843vw;
  $apx-1722: 89.686926vw;
  $apx-1723: 89.739009vw;
  $apx-1724: 89.791092vw;
  $apx-1725: 89.843175vw;
  $apx-1726: 89.895258vw;
  $apx-1727: 89.947341vw;
  $apx-1728: 89.999424vw;
  $apx-1729: 90.051507vw;
  $apx-1730: 90.10359vw;
  $apx-1731: 90.155673vw;
  $apx-1732: 90.207756vw;
  $apx-1733: 90.259839vw;
  $apx-1734: 90.311922vw;
  $apx-1735: 90.364005vw;
  $apx-1736: 90.416088vw;
  $apx-1737: 90.468171vw;
  $apx-1738: 90.520254vw;
  $apx-1739: 90.572337vw;
  $apx-1740: 90.62442vw;
  $apx-1741: 90.676503vw;
  $apx-1742: 90.728586vw;
  $apx-1743: 90.780669vw;
  $apx-1744: 90.832752vw;
  $apx-1745: 90.884835vw;
  $apx-1746: 90.936918vw;
  $apx-1747: 90.989001vw;
  $apx-1748: 91.041084vw;
  $apx-1749: 91.093167vw;
  $apx-1750: 91.14525vw;
  $apx-1751: 91.197333vw;
  $apx-1752: 91.249416vw;
  $apx-1753: 91.301499vw;
  $apx-1754: 91.353582vw;
  $apx-1755: 91.405665vw;
  $apx-1756: 91.457748vw;
  $apx-1757: 91.509831vw;
  $apx-1758: 91.561914vw;
  $apx-1759: 91.613997vw;
  $apx-1760: 91.66608vw;
  $apx-1761: 91.718163vw;
  $apx-1762: 91.770246vw;
  $apx-1763: 91.822329vw;
  $apx-1764: 91.874412vw;
  $apx-1765: 91.926495vw;
  $apx-1766: 91.978578vw;
  $apx-1767: 92.030661vw;
  $apx-1768: 92.082744vw;
  $apx-1769: 92.134827vw;
  $apx-1770: 92.18691vw;
  $apx-1771: 92.238993vw;
  $apx-1772: 92.291076vw;
  $apx-1773: 92.343159vw;
  $apx-1774: 92.395242vw;
  $apx-1775: 92.447325vw;
  $apx-1776: 92.499408vw;
  $apx-1777: 92.551491vw;
  $apx-1778: 92.603574vw;
  $apx-1779: 92.655657vw;
  $apx-1780: 92.70774vw;
  $apx-1781: 92.759823vw;
  $apx-1782: 92.811906vw;
  $apx-1783: 92.863989vw;
  $apx-1784: 92.916072vw;
  $apx-1785: 92.968155vw;
  $apx-1786: 93.020238vw;
  $apx-1787: 93.072321vw;
  $apx-1788: 93.124404vw;
  $apx-1789: 93.176487vw;
  $apx-1790: 93.22857vw;
  $apx-1791: 93.280653vw;
  $apx-1792: 93.332736vw;
  $apx-1793: 93.384819vw;
  $apx-1794: 93.436902vw;
  $apx-1795: 93.488985vw;
  $apx-1796: 93.541068vw;
  $apx-1797: 93.593151vw;
  $apx-1798: 93.645234vw;
  $apx-1799: 93.697317vw;
  $apx-1800: 93.7494vw;
  $apx-1801: 93.801483vw;
  $apx-1802: 93.853566vw;
  $apx-1803: 93.905649vw;
  $apx-1804: 93.957732vw;
  $apx-1805: 94.009815vw;
  $apx-1806: 94.061898vw;
  $apx-1807: 94.113981vw;
  $apx-1808: 94.166064vw;
  $apx-1809: 94.218147vw;
  $apx-1810: 94.27023vw;
  $apx-1811: 94.322313vw;
  $apx-1812: 94.374396vw;
  $apx-1813: 94.426479vw;
  $apx-1814: 94.478562vw;
  $apx-1815: 94.530645vw;
  $apx-1816: 94.582728vw;
  $apx-1817: 94.634811vw;
  $apx-1818: 94.686894vw;
  $apx-1819: 94.738977vw;
  $apx-1820: 94.79106vw;
  $apx-1821: 94.843143vw;
  $apx-1822: 94.895226vw;
  $apx-1823: 94.947309vw;
  $apx-1824: 94.999392vw;
  $apx-1825: 95.051475vw;
  $apx-1826: 95.103558vw;
  $apx-1827: 95.155641vw;
  $apx-1828: 95.207724vw;
  $apx-1829: 95.259807vw;
  $apx-1830: 95.31189vw;
  $apx-1831: 95.363973vw;
  $apx-1832: 95.416056vw;
  $apx-1833: 95.468139vw;
  $apx-1834: 95.520222vw;
  $apx-1835: 95.572305vw;
  $apx-1836: 95.624388vw;
  $apx-1837: 95.676471vw;
  $apx-1838: 95.728554vw;
  $apx-1839: 95.780637vw;
  $apx-1840: 95.83272vw;
  $apx-1841: 95.884803vw;
  $apx-1842: 95.936886vw;
  $apx-1843: 95.988969vw;
  $apx-1844: 96.041052vw;
  $apx-1845: 96.093135vw;
  $apx-1846: 96.145218vw;
  $apx-1847: 96.197301vw;
  $apx-1848: 96.249384vw;
  $apx-1849: 96.301467vw;
  $apx-1850: 96.35355vw;
  $apx-1851: 96.405633vw;
  $apx-1852: 96.457716vw;
  $apx-1853: 96.509799vw;
  $apx-1854: 96.561882vw;
  $apx-1855: 96.613965vw;
  $apx-1856: 96.666048vw;
  $apx-1857: 96.718131vw;
  $apx-1858: 96.770214vw;
  $apx-1859: 96.822297vw;
  $apx-1860: 96.87438vw;
  $apx-1861: 96.926463vw;
  $apx-1862: 96.978546vw;
  $apx-1863: 97.030629vw;
  $apx-1864: 97.082712vw;
  $apx-1865: 97.134795vw;
  $apx-1866: 97.186878vw;
  $apx-1867: 97.238961vw;
  $apx-1868: 97.291044vw;
  $apx-1869: 97.343127vw;
  $apx-1870: 97.39521vw;
  $apx-1871: 97.447293vw;
  $apx-1872: 97.499376vw;
  $apx-1873: 97.551459vw;
  $apx-1874: 97.603542vw;
  $apx-1875: 97.655625vw;
  $apx-1876: 97.707708vw;
  $apx-1877: 97.759791vw;
  $apx-1878: 97.811874vw;
  $apx-1879: 97.863957vw;
  $apx-1880: 97.91604vw;
  $apx-1881: 97.968123vw;
  $apx-1882: 98.020206vw;
  $apx-1883: 98.072289vw;
  $apx-1884: 98.124372vw;
  $apx-1885: 98.176455vw;
  $apx-1886: 98.228538vw;
  $apx-1887: 98.280621vw;
  $apx-1888: 98.332704vw;
  $apx-1889: 98.384787vw;
  $apx-1890: 98.43687vw;
  $apx-1891: 98.488953vw;
  $apx-1892: 98.541036vw;
  $apx-1893: 98.593119vw;
  $apx-1894: 98.645202vw;
  $apx-1895: 98.697285vw;
  $apx-1896: 98.749368vw;
  $apx-1897: 98.801451vw;
  $apx-1898: 98.853534vw;
  $apx-1899: 98.905617vw;
  $apx-1900: 98.9577vw;
  $apx-1901: 99.009783vw;
  $apx-1902: 99.061866vw;
  $apx-1903: 99.113949vw;
  $apx-1904: 99.166032vw;
  $apx-1905: 99.218115vw;
  $apx-1906: 99.270198vw;
  $apx-1907: 99.322281vw;
  $apx-1908: 99.374364vw;
  $apx-1909: 99.426447vw;
  $apx-1910: 99.47853vw;
  $apx-1911: 99.530613vw;
  $apx-1912: 99.582696vw;
  $apx-1913: 99.634779vw;
  $apx-1914: 99.686862vw;
  $apx-1915: 99.738945vw;
  $apx-1916: 99.791028vw;
  $apx-1917: 99.843111vw;
  $apx-1918: 99.895194vw;
  $apx-1919: 99.947277vw;
  $apx-1920: 99.99936vw;

  $apx-m-1: 0.277778vw;
  $apx-m-2: 0.555556vw;
  $apx-m-3: 0.833334vw;
  $apx-m-4: 1.111112vw;
  $apx-m-5: 1.38889vw;
  $apx-m-6: 1.666668vw;
  $apx-m-7: 1.944446vw;
  $apx-m-8: 2.222224vw;
  $apx-m-9: 2.500002vw;
  $apx-m-10: 2.77778vw;
  $apx-m-11: 3.055558vw;
  $apx-m-12: 3.333336vw;
  $apx-m-13: 3.611114vw;
  $apx-m-14: 3.888892vw;
  $apx-m-15: 4.16667vw;
  $apx-m-16: 4.444448vw;
  $apx-m-17: 4.722226vw;
  $apx-m-18: 5.000004vw;
  $apx-m-19: 5.277782vw;
  $apx-m-20: 5.55556vw;
  $apx-m-21: 5.833338vw;
  $apx-m-22: 6.111116vw;
  $apx-m-23: 6.388894vw;
  $apx-m-24: 6.666672vw;
  $apx-m-25: 6.94445vw;
  $apx-m-26: 7.222228vw;
  $apx-m-27: 7.500006vw;
  $apx-m-28: 7.777784vw;
  $apx-m-29: 8.055562vw;
  $apx-m-30: 8.33334vw;
  $apx-m-31: 8.611118vw;
  $apx-m-32: 8.888896vw;
  $apx-m-33: 9.166674vw;
  $apx-m-34: 9.444452vw;
  $apx-m-35: 9.72223vw;
  $apx-m-36: 10.000008vw;
  $apx-m-37: 10.277786vw;
  $apx-m-38: 10.555564vw;
  $apx-m-39: 10.833342vw;
  $apx-m-40: 11.11112vw;
  $apx-m-41: 11.388898vw;
  $apx-m-42: 11.666676vw;
  $apx-m-43: 11.944454vw;
  $apx-m-44: 12.222232vw;
  $apx-m-45: 12.50001vw;
  $apx-m-46: 12.777788vw;
  $apx-m-47: 13.055566vw;
  $apx-m-48: 13.333344vw;
  $apx-m-49: 13.611122vw;
  $apx-m-50: 13.8889vw;
  $apx-m-51: 14.166678vw;
  $apx-m-52: 14.444456vw;
  $apx-m-53: 14.722234vw;
  $apx-m-54: 15.000012vw;
  $apx-m-55: 15.27779vw;
  $apx-m-56: 15.555568vw;
  $apx-m-57: 15.833346vw;
  $apx-m-58: 16.111124vw;
  $apx-m-59: 16.388902vw;
  $apx-m-60: 16.66668vw;
  $apx-m-61: 16.944458vw;
  $apx-m-62: 17.222236vw;
  $apx-m-63: 17.500014vw;
  $apx-m-64: 17.777792vw;
  $apx-m-65: 18.05557vw;
  $apx-m-66: 18.333348vw;
  $apx-m-67: 18.611126vw;
  $apx-m-68: 18.888904vw;
  $apx-m-69: 19.166682vw;
  $apx-m-70: 19.44446vw;
  $apx-m-71: 19.722238vw;
  $apx-m-72: 20.000016vw;
  $apx-m-73: 20.277794vw;
  $apx-m-74: 20.555572vw;
  $apx-m-75: 20.83335vw;
  $apx-m-76: 21.111128vw;
  $apx-m-77: 21.388906vw;
  $apx-m-78: 21.666684vw;
  $apx-m-79: 21.944462vw;
  $apx-m-80: 22.22224vw;
  $apx-m-81: 22.500018vw;
  $apx-m-82: 22.777796vw;
  $apx-m-83: 23.055574vw;
  $apx-m-84: 23.333352vw;
  $apx-m-85: 23.61113vw;
  $apx-m-86: 23.888908vw;
  $apx-m-87: 24.166686vw;
  $apx-m-88: 24.444464vw;
  $apx-m-89: 24.722242vw;
  $apx-m-90: 25.00002vw;
  $apx-m-91: 25.277798vw;
  $apx-m-92: 25.555576vw;
  $apx-m-93: 25.833354vw;
  $apx-m-94: 26.111132vw;
  $apx-m-95: 26.38891vw;
  $apx-m-96: 26.666688vw;
  $apx-m-97: 26.944466vw;
  $apx-m-98: 27.222244vw;
  $apx-m-99: 27.500022vw;
  $apx-m-100: 27.7778vw;
  $apx-m-101: 28.055578vw;
  $apx-m-102: 28.333356vw;
  $apx-m-103: 28.611134vw;
  $apx-m-104: 28.888912vw;
  $apx-m-105: 29.16669vw;
  $apx-m-106: 29.444468vw;
  $apx-m-107: 29.722246vw;
  $apx-m-108: 30.000024vw;
  $apx-m-109: 30.277802vw;
  $apx-m-110: 30.55558vw;
  $apx-m-111: 30.833358vw;
  $apx-m-112: 31.111136vw;
  $apx-m-113: 31.388914vw;
  $apx-m-114: 31.666692vw;
  $apx-m-115: 31.94447vw;
  $apx-m-116: 32.222248vw;
  $apx-m-117: 32.500026vw;
  $apx-m-118: 32.777804vw;
  $apx-m-119: 33.055582vw;
  $apx-m-120: 33.33336vw;
  $apx-m-121: 33.611138vw;
  $apx-m-122: 33.888916vw;
  $apx-m-123: 34.166694vw;
  $apx-m-124: 34.444472vw;
  $apx-m-125: 34.72225vw;
  $apx-m-126: 35.000028vw;
  $apx-m-127: 35.277806vw;
  $apx-m-128: 35.555584vw;
  $apx-m-129: 35.833362vw;
  $apx-m-130: 36.11114vw;
  $apx-m-131: 36.388918vw;
  $apx-m-132: 36.666696vw;
  $apx-m-133: 36.944474vw;
  $apx-m-134: 37.222252vw;
  $apx-m-135: 37.50003vw;
  $apx-m-136: 37.777808vw;
  $apx-m-137: 38.055586vw;
  $apx-m-138: 38.333364vw;
  $apx-m-139: 38.611142vw;
  $apx-m-140: 38.88892vw;
  $apx-m-141: 39.166698vw;
  $apx-m-142: 39.444476vw;
  $apx-m-143: 39.722254vw;
  $apx-m-144: 40.000032vw;
  $apx-m-145: 40.27781vw;
  $apx-m-146: 40.555588vw;
  $apx-m-147: 40.833366vw;
  $apx-m-148: 41.111144vw;
  $apx-m-149: 41.388922vw;
  $apx-m-150: 41.6667vw;
  $apx-m-151: 41.944478vw;
  $apx-m-152: 42.222256vw;
  $apx-m-153: 42.500034vw;
  $apx-m-154: 42.777812vw;
  $apx-m-155: 43.05559vw;
  $apx-m-156: 43.333368vw;
  $apx-m-157: 43.611146vw;
  $apx-m-158: 43.888924vw;
  $apx-m-159: 44.166702vw;
  $apx-m-160: 44.44448vw;
  $apx-m-161: 44.722258vw;
  $apx-m-162: 45.000036vw;
  $apx-m-163: 45.277814vw;
  $apx-m-164: 45.555592vw;
  $apx-m-165: 45.83337vw;
  $apx-m-166: 46.111148vw;
  $apx-m-167: 46.388926vw;
  $apx-m-168: 46.666704vw;
  $apx-m-169: 46.944482vw;
  $apx-m-170: 47.22226vw;
  $apx-m-171: 47.500038vw;
  $apx-m-172: 47.777816vw;
  $apx-m-173: 48.055594vw;
  $apx-m-174: 48.333372vw;
  $apx-m-175: 48.61115vw;
  $apx-m-176: 48.888928vw;
  $apx-m-177: 49.166706vw;
  $apx-m-178: 49.444484vw;
  $apx-m-179: 49.722262vw;
  $apx-m-180: 50.00004vw;
  $apx-m-181: 50.277818vw;
  $apx-m-182: 50.555596vw;
  $apx-m-183: 50.833374vw;
  $apx-m-184: 51.111152vw;
  $apx-m-185: 51.38893vw;
  $apx-m-186: 51.666708vw;
  $apx-m-187: 51.944486vw;
  $apx-m-188: 52.222264vw;
  $apx-m-189: 52.500042vw;
  $apx-m-190: 52.77782vw;
  $apx-m-191: 53.055598vw;
  $apx-m-192: 53.333376vw;
  $apx-m-193: 53.611154vw;
  $apx-m-194: 53.888932vw;
  $apx-m-195: 54.16671vw;
  $apx-m-196: 54.444488vw;
  $apx-m-197: 54.722266vw;
  $apx-m-198: 55.000044vw;
  $apx-m-199: 55.277822vw;
  $apx-m-200: 55.5556vw;
  $apx-m-201: 55.833378vw;
  $apx-m-202: 56.111156vw;
  $apx-m-203: 56.388934vw;
  $apx-m-204: 56.666712vw;
  $apx-m-205: 56.94449vw;
  $apx-m-206: 57.222268vw;
  $apx-m-207: 57.500046vw;
  $apx-m-208: 57.777824vw;
  $apx-m-209: 58.055602vw;
  $apx-m-210: 58.33338vw;
  $apx-m-211: 58.611158vw;
  $apx-m-212: 58.888936vw;
  $apx-m-213: 59.166714vw;
  $apx-m-214: 59.444492vw;
  $apx-m-215: 59.72227vw;
  $apx-m-216: 60.000048vw;
  $apx-m-217: 60.277826vw;
  $apx-m-218: 60.555604vw;
  $apx-m-219: 60.833382vw;
  $apx-m-220: 61.11116vw;
  $apx-m-221: 61.388938vw;
  $apx-m-222: 61.666716vw;
  $apx-m-223: 61.944494vw;
  $apx-m-224: 62.222272vw;
  $apx-m-225: 62.50005vw;
  $apx-m-226: 62.777828vw;
  $apx-m-227: 63.055606vw;
  $apx-m-228: 63.333384vw;
  $apx-m-229: 63.611162vw;
  $apx-m-230: 63.88894vw;
  $apx-m-231: 64.166718vw;
  $apx-m-232: 64.444496vw;
  $apx-m-233: 64.722274vw;
  $apx-m-234: 65.000052vw;
  $apx-m-235: 65.27783vw;
  $apx-m-236: 65.555608vw;
  $apx-m-237: 65.833386vw;
  $apx-m-238: 66.111164vw;
  $apx-m-239: 66.388942vw;
  $apx-m-240: 66.66672vw;
  $apx-m-241: 66.944498vw;
  $apx-m-242: 67.222276vw;
  $apx-m-243: 67.500054vw;
  $apx-m-244: 67.777832vw;
  $apx-m-245: 68.05561vw;
  $apx-m-246: 68.333388vw;
  $apx-m-247: 68.611166vw;
  $apx-m-248: 68.888944vw;
  $apx-m-249: 69.166722vw;
  $apx-m-250: 69.4445vw;
  $apx-m-251: 69.722278vw;
  $apx-m-252: 70.000056vw;
  $apx-m-253: 70.277834vw;
  $apx-m-254: 70.555612vw;
  $apx-m-255: 70.83339vw;
  $apx-m-256: 71.111168vw;
  $apx-m-257: 71.388946vw;
  $apx-m-258: 71.666724vw;
  $apx-m-259: 71.944502vw;
  $apx-m-260: 72.22228vw;
  $apx-m-261: 72.500058vw;
  $apx-m-262: 72.777836vw;
  $apx-m-263: 73.055614vw;
  $apx-m-264: 73.333392vw;
  $apx-m-265: 73.61117vw;
  $apx-m-266: 73.888948vw;
  $apx-m-267: 74.166726vw;
  $apx-m-268: 74.444504vw;
  $apx-m-269: 74.722282vw;
  $apx-m-270: 75.00006vw;
  $apx-m-271: 75.277838vw;
  $apx-m-272: 75.555616vw;
  $apx-m-273: 75.833394vw;
  $apx-m-274: 76.111172vw;
  $apx-m-275: 76.38895vw;
  $apx-m-276: 76.666728vw;
  $apx-m-277: 76.944506vw;
  $apx-m-278: 77.222284vw;
  $apx-m-279: 77.500062vw;
  $apx-m-280: 77.77784vw;
  $apx-m-281: 78.055618vw;
  $apx-m-282: 78.333396vw;
  $apx-m-283: 78.611174vw;
  $apx-m-284: 78.888952vw;
  $apx-m-285: 79.16673vw;
  $apx-m-286: 79.444508vw;
  $apx-m-287: 79.722286vw;
  $apx-m-288: 80.000064vw;
  $apx-m-289: 80.277842vw;
  $apx-m-290: 80.55562vw;
  $apx-m-291: 80.833398vw;
  $apx-m-292: 81.111176vw;
  $apx-m-293: 81.388954vw;
  $apx-m-294: 81.666732vw;
  $apx-m-295: 81.94451vw;
  $apx-m-296: 82.222288vw;
  $apx-m-297: 82.500066vw;
  $apx-m-298: 82.777844vw;
  $apx-m-299: 83.055622vw;
  $apx-m-300: 83.3334vw;
  $apx-m-301: 83.611178vw;
  $apx-m-302: 83.888956vw;
  $apx-m-303: 84.166734vw;
  $apx-m-304: 84.444512vw;
  $apx-m-305: 84.72229vw;
  $apx-m-306: 85.000068vw;
  $apx-m-307: 85.277846vw;
  $apx-m-308: 85.555624vw;
  $apx-m-309: 85.833402vw;
  $apx-m-310: 86.11118vw;
  $apx-m-311: 86.388958vw;
  $apx-m-312: 86.666736vw;
  $apx-m-313: 86.944514vw;
  $apx-m-314: 87.222292vw;
  $apx-m-315: 87.50007vw;
  $apx-m-316: 87.777848vw;
  $apx-m-317: 88.055626vw;
  $apx-m-318: 88.333404vw;
  $apx-m-319: 88.611182vw;
  $apx-m-320: 88.88896vw;
  $apx-m-321: 89.166738vw;
  $apx-m-322: 89.444516vw;
  $apx-m-323: 89.722294vw;
  $apx-m-324: 90.000072vw;
  $apx-m-325: 90.27785vw;
  $apx-m-326: 90.555628vw;
  $apx-m-327: 90.833406vw;
  $apx-m-328: 91.111184vw;
  $apx-m-329: 91.388962vw;
  $apx-m-330: 91.66674vw;
  $apx-m-331: 91.944518vw;
  $apx-m-332: 92.222296vw;
  $apx-m-333: 92.500074vw;
  $apx-m-334: 92.777852vw;
  $apx-m-335: 93.05563vw;
  $apx-m-336: 93.333408vw;
  $apx-m-337: 93.611186vw;
  $apx-m-338: 93.888964vw;
  $apx-m-339: 94.166742vw;
  $apx-m-340: 94.44452vw;
  $apx-m-341: 94.722298vw;
  $apx-m-342: 95.000076vw;
  $apx-m-343: 95.277854vw;
  $apx-m-344: 95.555632vw;
  $apx-m-345: 95.83341vw;
  $apx-m-346: 96.111188vw;
  $apx-m-347: 96.388966vw;
  $apx-m-348: 96.666744vw;
  $apx-m-349: 96.944522vw;
  $apx-m-350: 97.2223vw;
  $apx-m-351: 97.500078vw;
  $apx-m-352: 97.777856vw;
  $apx-m-353: 98.055634vw;
  $apx-m-354: 98.333412vw;
  $apx-m-355: 98.61119vw;
  $apx-m-356: 98.888968vw;
  $apx-m-357: 99.166746vw;
  $apx-m-358: 99.444524vw;
  $apx-m-359: 99.722302vw;
  $apx-m-360: 100.00008vw;