@import "./apx.scss";

.text-orange__deep { color: #FF7A00; }
.text-orange { color: #FF8A00; }
.text-purple { color: #B24C4C; }
.text-purple__deep { color: #CE1717; }
.text-green { color: #289C15; }
.text-red { color: #EC6A6A; }
.text-red__deep { color: #D74141; }
.text-blue { color: #0075FF; }
.text-gray { color: #656565; }
.text-gray__dark { color: #A6A6A6; }
.text-gray__light { color: #818181; }
.text-gray__casper { color: #d6d3d3; }

.text-50 {

  font-size: $apx-50;
  line-height: $apx-57;

}

.text-49 {

  font-size: $apx-49;
  line-height: $apx-55;

}

.text-48 {

  font-size: $apx-48;
  line-height: $apx-53;

}

.text-47 {

  font-size: $apx-47;
  line-height: $apx-51;

}

.text-46 {

  font-size: $apx-46;
  line-height: $apx-50;

}

.text-45 {

  font-size: $apx-45;
  line-height: $apx-47;

}

.text-44 {

  font-size: $apx-44;
  line-height: $apx-48;

}

.text-43 {

  font-size: $apx-43;
  line-height: $apx-45;

}

.text-42 {

  font-size: $apx-42;
  line-height: $apx-44;

}

.text-41 {

  font-size: $apx-41;
  line-height: $apx-43;

}

.text-40 {

  font-size: $apx-40;
  line-height: $apx-42;

}

.text-39 {

  font-size: $apx-39;
  line-height: $apx-41;

}


.text-38 {

  font-size: $apx-38;
  line-height: $apx-40;

}


.text-37 {

  font-size: $apx-37;
  line-height: $apx-39;

}


.text-36 {

  font-size: $apx-36;
  line-height: $apx-38;

}


.text-35 {

  font-size: $apx-33;
  line-height: $apx-37;

}

.text-34 {

  font-size: $apx-34;
  line-height: $apx-36;

}

.text-33 {

  font-size: $apx-33;
  line-height: $apx-35;

}

.text-32 {

  font-size: $apx-32;
  line-height: $apx-36;

}

.text-31 {

  font-size: $apx-31;
  line-height: $apx-33;

}

.text-30 {

  font-size: $apx-30;
  line-height: $apx-32;

}

.text-29 {

  font-size: $apx-29;
  line-height: $apx-31;

}

.text-28 {

  font-size: $apx-28;
  line-height: $apx-30;

}

.text-27 {

  font-size: $apx-27;
  line-height: $apx-29;

}

.text-26 {

  font-size: $apx-26;
  line-height: $apx-28;

}

.text-25 {

  font-size: $apx-25;
  line-height: $apx-30;

}

.text-24 {

  font-size: $apx-24;
  line-height: $apx-28;

}

.text-23 {

  font-size: $apx-23;
  line-height: $apx-27;

}

.text-22 {

  font-size: $apx-22;
  line-height: $apx-24;

}

.text-21 {

  font-size: $apx-21;
  line-height: $apx-23;

}

.text-20 {

  font-size: $apx-20;
  line-height: $apx-22;

}

.text-19 {

  font-size: $apx-19;
  line-height: $apx-21;

}

.text-18 {

  font-size: $apx-18;
  line-height: $apx-20;

}

.text-17 {

  font-size: $apx-17;
  line-height: $apx-19;

}

.text-16 {

  font-size: $apx-16;
  line-height: $apx-18;

}

.text-15 {

  font-size: $apx-15;
  line-height: $apx-17;

}

.text-14 {

  font-size: $apx-14;
  line-height: $apx-16;

}

.text-13 {

  font-size: $apx-13;
  line-height: $apx-15;

}

.text-12 {

  font-size: $apx-12;
  line-height: $apx-14;

}

@media screen and ( max-width: 960px ) {  

  .text-50 {

    font-size: $apx-m-25;
    line-height: $apx-m-30;
  
  }

  .text-49 {

    font-size: $apx-m-24;
    line-height: $apx-m-28;
  
  }

  .text-48 {

    font-size: $apx-m-23;
    line-height: $apx-m-26;
  
  }

  .text-47 {

    font-size: $apx-m-22;
    line-height: $apx-m-24;
  
  }

  .text-46 {

    font-size: $apx-m-20;
    line-height: $apx-m-22;
  
  }

  .text-45 {

    font-size: $apx-m-19;
    line-height: $apx-m-21;
  
  }


  .text-44 {

    font-size: $apx-m-18;
    line-height: $apx-m-20;
  
  }
  
  .text-35 {
  
    font-size: $apx-m-20;
    line-height: $apx-m-22;
  
  }

  .text-34 {
  
    font-size: $apx-m-19;
    line-height: $apx-m-21;
  
  }

  .text-33 {
  
    font-size: $apx-m-18;
    line-height: $apx-m-20;
  
  }

  .text-32 {
  
    font-size: $apx-m-16;
    line-height: $apx-m-18;
  
  }

  .text-31 {

    font-size: $apx-m-25;
    line-height: $apx-m-28;
  
  }

  .text-24 {

    font-size: $apx-m-15;
    line-height: $apx-m-18;
  
  }
  
  .text-23 {
  
    font-size: $apx-m-18;
    line-height: $apx-m-20;
  
  }

  .text-22 {

    font-size: $apx-m-15;
    line-height: $apx-m-18;
  
  }

  .text-21 {

    font-size: $apx-m-14;
    line-height: $apx-m-16;
  
  }

  .text-19 {

    font-size: $apx-m-19;
    line-height: $apx-m-20;
  
  }

  .text-18 {
  
    font-size: $apx-m-18;
    line-height: $apx-m-19;
  
  }

  .text-17 {
  
    font-size: $apx-m-17;
    line-height: $apx-m-18;
  
  }

  .text-16 {
  
    font-size: $apx-m-16;
    line-height: $apx-m-17;
  
  }

  .text-15 {
  
    font-size: $apx-m-15;
    line-height: $apx-m-16;
  
  }

  .text-14 {
  
    font-size: $apx-m-14;
    line-height: $apx-m-15;
  
  }

  .text-13 {
  
    font-size: $apx-m-13;
    line-height: $apx-m-15;
  
  }

  .text-12 {
  
    font-size: $apx-m-12;
    line-height: $apx-m-14;
  
  }

  .text-36-m {

    font-size: $apx-m-36;
    line-height: $apx-m-38;
  
  }

  .text-30-m {

    font-size: $apx-m-30;
    line-height: $apx-m-32;
  
  }

  .text-32-m {

    font-size: $apx-m-32;
    line-height: $apx-m-35;
  
  }

  .text-31-m {

    font-size: $apx-m-31;
    line-height: $apx-m-32;
  
  }

  .text-29-m {

    font-size: $apx-m-29;
    line-height: $apx-m-31;
  
  }

  .text-28-m {

    font-size: $apx-m-28;
    line-height: $apx-m-30;
  
  }

  .text-27-m {

    font-size: $apx-m-27;
    line-height: $apx-m-29;
  
  }

  .text-26-m {

    font-size: $apx-m-26;
    line-height: $apx-m-38;
  
  }

  .text-25-m {

    font-size: $apx-m-25;
    line-height: $apx-m-30;
  
  }
  
  .text-24-m {
  
    font-size: $apx-m-24;
    line-height: $apx-m-28;
  
  }
  
  .text-23-m {
  
    font-size: $apx-m-23;
    line-height: $apx-m-25;
  
  }

  .text-22-m {
  
    font-size: $apx-m-22;
    line-height: $apx-m-24;
  
  }

  .text-21-m {
  
    font-size: $apx-m-21;
    line-height: $apx-m-23;
  
  }

  .text-20-m {
  
    font-size: $apx-m-20;
    line-height: $apx-m-22;
  
  }

  .text-19-m {
  
    font-size: $apx-m-19;
    line-height: $apx-m-21;
  
  }

  .text-18-m {
  
    font-size: $apx-m-18;
    line-height: $apx-m-20;
  
  }

  .text-17-m {
  
    font-size: $apx-m-17;
    line-height: $apx-m-19;
  
  }
  
  .text-16-m {
  
    font-size: $apx-m-16;
    line-height: $apx-m-18;
  
  }

  .text-15-m {
  
    font-size: $apx-m-15;
    line-height: $apx-m-17;
  
  }

  .text-14-m {
  
    font-size: $apx-m-14;
    line-height: $apx-m-16;
  
  }

  .text-13-m {
  
    font-size: $apx-m-13;
    line-height: $apx-m-15;
  
  }

}