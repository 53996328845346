@import "../../../styles/apx.scss";
.newclient {

  &__bell {

    right: 0;
    top: $apx-50;
    margin: 0 auto;
    width: $apx-569;
    height: $apx-460;
    margin-top: $apx-88;

  }

  &__calendar { width: $apx-906; }

  &__client {

    margin: 0 auto;
    margin-top: $apx-88;
    width: $apx-1110;

  }

}

@media screen and ( max-width: 960px ) {
  .newclient {

    &__calendar { width: $apx-m-300; }

    &__client {

      width: $apx-m-300;
      margin-top: $apx-m-60;
      margin-left: -$apx-m-5;
  
    }

    .text-23 {

      text-align: left;
      font-size: $apx-m-14;
      line-height: $apx-m-18;

    }

  }

}