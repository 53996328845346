@import "../../../styles/apx.scss";
.shopboughts {

  padding-left: $apx-20;

  .text-23 { line-height: $apx-30; }

  &__first {
    
    width: $apx-440;
    height: $apx-320;
  
  }

  &__second { width: $apx-420; margin-left: $apx-10; }

  &__button { margin-top: $apx-100; margin-left: -$apx-25; }

}

@media screen and ( max-width: 960px ) {

  .shopboughts {

    padding-left: unset;

    .flex { display: block; }

    .ml-40, .ml-60, .ml-80 { margin-left: unset; }

    .text-23 { line-height: $apx-m-20; }
  
    &__secondtext { width: $apx-600; }
  
    &__first {
      
      width: $apx-m-300;
      height: unset;
    
    }
  
    &__second { width: $apx-m-300; }
  
    &__button { margin-top: $apx-m-25; left: unset; }
  
  }

}