 @import "../../../styles/apx.scss";
.instructions {

  .instr1 {

    width: $apx-365;
    height: $apx-505;
    
  }

  .instr2 {

    width: $apx-446;
    height: $apx-585;
    
  }

}

@media screen and ( max-width: 960px ) {
  .instructions {

    &__row {

      .text-right { text-align: left !important; }

      &.mt-38 { margin-top: $apx-m-14; }

    }

    .mt-40 { margin-top: $apx-m-17; }
    .mt-52 { margin-top: $apx-m-26; }

    .instr1 { 

      height: auto;
      width: $apx-m-305;
      margin-top: $apx-m-15;

    }

    .instr2 { 

      height: auto;
      width: $apx-m-305;
      margin-top: $apx-m-15;

    }

    .text-47 { 
      
      font-size: $apx-m-22;
      line-height: $apx-m-24;
    
    }
    
    .text-23 { 
      
      font-size: $apx-m-15;
      line-height: $apx-m-16;

    }

  }

}