
@import "./apx.scss";

.fixed { position: fixed; }
.relative { position: relative; }
.absolute { position: absolute; }

.flex-1 { flex: 1 1; }
.flex { display: flex; }
.flex-col { flex-direction: column; }
.justify-evenly { justify-content: space-evenly; }
.justify-between { justify-content: space-between; }
.flex-row-reverse { flex-direction: row-reverse; }
.flex-col-reverse { flex-direction: column-reverse; }
.justify-center { justify-content: center; }
.justify-end { justify-content: flex-end; }
.justify-start { justify-content: flex-start; }
.items-center { align-items: center; }
.items-end { align-items: flex-end; }
.text-left { text-align: left; }
.text-right { text-align: right; }
.text-center { text-align: center; }
.font-thin { font-weight: 100; }
.font-extralight { font-weight: 200; }
.font-light { font-weight: 300; }
.font-normal { font-weight: 400; }
.font-medium { font-weight: 500; }
.font-semibold { font-weight: 600; }
.font-bold { font-weight: 700; }
.font-extrabold { font-weight: 800; }
.font-black { font-weight: 900; }
.font-extrablack { font-weight: 1000; }


.mt-350 { margin-top: $apx-350; }
.mt-250 { margin-top: $apx-250; }
.mt-240 { margin-top: $apx-240; }
.mt-230 { margin-top: $apx-230; }
.mt-220 { margin-top: $apx-220; }
.mt-210 { margin-top: $apx-210; }
.mt-200 { margin-top: $apx-200; }
.mt-150 { margin-top: $apx-150; }
.mt-140 { margin-top: $apx-140; }
.mt-130 { margin-top: $apx-130; }
.mt-120 { margin-top: $apx-120; }
.mt-110 { margin-top: $apx-110; }
.mt-100 { margin-top: $apx-100; }
.mt-99 { margin-top: $apx-99; }
.mt-98 { margin-top: $apx-98; }
.mt-97 { margin-top: $apx-97; }
.mt-96 { margin-top: $apx-96; }
.mt-95 { margin-top: $apx-95; }
.mt-94 { margin-top: $apx-94; }
.mt-93 { margin-top: $apx-93; }
.mt-92 { margin-top: $apx-92; }
.mt-91 { margin-top: $apx-91; }
.mt-90 { margin-top: $apx-90; }
.mt-89 { margin-top: $apx-89; }
.mt-88 { margin-top: $apx-88; }
.mt-87 { margin-top: $apx-87; }
.mt-86 { margin-top: $apx-86; }
.mt-85 { margin-top: $apx-85; }
.mt-84 { margin-top: $apx-84; }
.mt-83 { margin-top: $apx-83; }
.mt-82 { margin-top: $apx-82; }
.mt-81 { margin-top: $apx-81; }
.mt-80 { margin-top: $apx-80; }
.mt-79 { margin-top: $apx-79; }
.mt-78 { margin-top: $apx-78; }
.mt-77 { margin-top: $apx-77; }
.mt-76 { margin-top: $apx-76; }
.mt-75 { margin-top: $apx-75; }
.mt-74 { margin-top: $apx-74; }
.mt-73 { margin-top: $apx-73; }
.mt-72 { margin-top: $apx-72; }
.mt-71 { margin-top: $apx-71; }
.mt-70 { margin-top: $apx-70; }
.mt-69 { margin-top: $apx-69; }
.mt-68 { margin-top: $apx-68; }
.mt-67 { margin-top: $apx-67; }
.mt-66 { margin-top: $apx-66; }
.mt-65 { margin-top: $apx-65; }
.mt-64 { margin-top: $apx-64; }
.mt-63 { margin-top: $apx-63; }
.mt-62 { margin-top: $apx-62; }
.mt-61 { margin-top: $apx-61; }
.mt-60 { margin-top: $apx-60; }
.mt-59 { margin-top: $apx-59; }
.mt-58 { margin-top: $apx-58; }
.mt-57 { margin-top: $apx-57; }
.mt-56 { margin-top: $apx-56; }
.mt-55 { margin-top: $apx-55; }
.mt-54 { margin-top: $apx-54; }
.mt-53 { margin-top: $apx-53; }
.mt-52 { margin-top: $apx-52; }
.mt-51 { margin-top: $apx-51; }
.mt-50 { margin-top: $apx-50; }
.mt-49 { margin-top: $apx-49; }
.mt-48 { margin-top: $apx-48; }
.mt-47 { margin-top: $apx-47; }
.mt-46 { margin-top: $apx-46; }
.mt-45 { margin-top: $apx-45; }
.mt-44 { margin-top: $apx-44; }
.mt-43 { margin-top: $apx-43; }
.mt-42 { margin-top: $apx-42; }
.mt-41 { margin-top: $apx-41; }
.mt-40 { margin-top: $apx-40; }
.mt-39 { margin-top: $apx-39; }
.mt-38 { margin-top: $apx-38; }
.mt-37 { margin-top: $apx-37; }
.mt-36 { margin-top: $apx-36; }
.mt-35 { margin-top: $apx-35; }
.mt-34 { margin-top: $apx-34; }
.mt-33 { margin-top: $apx-34; }
.mt-32 { margin-top: $apx-32; }
.mt-31 { margin-top: $apx-31; }
.mt-30 { margin-top: $apx-30; }
.mt-29 { margin-top: $apx-29; }
.mt-28 { margin-top: $apx-28; }
.mt-27 { margin-top: $apx-27; }
.mt-26 { margin-top: $apx-26; }
.mt-25 { margin-top: $apx-25; }
.mt-24 { margin-top: $apx-24; }
.mt-23 { margin-top: $apx-23; }
.mt-22 { margin-top: $apx-22; }
.mt-21 { margin-top: $apx-21; }
.mt-20 { margin-top: $apx-20; }
.mt-19 { margin-top: $apx-19; }
.mt-18 { margin-top: $apx-18; }
.mt-17 { margin-top: $apx-17; }
.mt-16 { margin-top: $apx-16; }
.mt-15 { margin-top: $apx-15; }
.mt-14 { margin-top: $apx-14; }
.mt-13 { margin-top: $apx-13; }
.mt-12 { margin-top: $apx-12; }
.mt-11 { margin-top: $apx-11; }
.mt-10 { margin-top: $apx-10; }
.mt-9 { margin-top: $apx-9; }
.mt-8 { margin-top: $apx-8; }
.mt-7 { margin-top: $apx-7; }
.mt-6 { margin-top: $apx-6; }
.mt-5 { margin-top: $apx-5; }
.mt-4 { margin-top: $apx-4; }
.mt-3 { margin-top: $apx-3; }
.mt-2 { margin-top: $apx-2; }
.mt-1 { margin-top: $apx-1; }

.mb-350 { margin-bottom: $apx-350; }
.mb-250 { margin-bottom: $apx-250; }
.mb-240 { margin-bottom: $apx-240; }
.mb-230 { margin-bottom: $apx-230; }
.mb-220 { margin-bottom: $apx-220; }
.mb-210 { margin-bottom: $apx-210; }
.mb-200 { margin-bottom: $apx-200; }
.mb-150 { margin-bottom: $apx-150; }
.mb-140 { margin-bottom: $apx-140; }
.mb-130 { margin-bottom: $apx-130; }
.mb-120 { margin-bottom: $apx-120; }
.mb-110 { margin-bottom: $apx-110; }
.mb-100 { margin-bottom: $apx-100; }
.mb-99 { margin-bottom: $apx-99; }
.mb-98 { margin-bottom: $apx-98; }
.mb-97 { margin-bottom: $apx-97; }
.mb-96 { margin-bottom: $apx-96; }
.mb-95 { margin-bottom: $apx-95; }
.mb-94 { margin-bottom: $apx-94; }
.mb-93 { margin-bottom: $apx-93; }
.mb-92 { margin-bottom: $apx-92; }
.mb-91 { margin-bottom: $apx-91; }
.mb-90 { margin-bottom: $apx-90; }
.mb-89 { margin-bottom: $apx-89; }
.mb-88 { margin-bottom: $apx-88; }
.mb-87 { margin-bottom: $apx-87; }
.mb-86 { margin-bottom: $apx-86; }
.mb-85 { margin-bottom: $apx-85; }
.mb-84 { margin-bottom: $apx-84; }
.mb-83 { margin-bottom: $apx-83; }
.mb-82 { margin-bottom: $apx-82; }
.mb-81 { margin-bottom: $apx-81; }
.mb-80 { margin-bottom: $apx-80; }
.mb-79 { margin-bottom: $apx-79; }
.mb-78 { margin-bottom: $apx-78; }
.mb-77 { margin-bottom: $apx-77; }
.mb-76 { margin-bottom: $apx-76; }
.mb-75 { margin-bottom: $apx-75; }
.mb-74 { margin-bottom: $apx-74; }
.mb-73 { margin-bottom: $apx-73; }
.mb-72 { margin-bottom: $apx-72; }
.mb-71 { margin-bottom: $apx-71; }
.mb-70 { margin-bottom: $apx-70; }
.mb-69 { margin-bottom: $apx-69; }
.mb-68 { margin-bottom: $apx-68; }
.mb-67 { margin-bottom: $apx-67; }
.mb-66 { margin-bottom: $apx-66; }
.mb-65 { margin-bottom: $apx-65; }
.mb-64 { margin-bottom: $apx-64; }
.mb-63 { margin-bottom: $apx-63; }
.mb-62 { margin-bottom: $apx-62; }
.mb-61 { margin-bottom: $apx-61; }
.mb-60 { margin-bottom: $apx-60; }
.mb-59 { margin-bottom: $apx-59; }
.mb-58 { margin-bottom: $apx-58; }
.mb-57 { margin-bottom: $apx-57; }
.mb-56 { margin-bottom: $apx-56; }
.mb-55 { margin-bottom: $apx-55; }
.mb-54 { margin-bottom: $apx-54; }
.mb-53 { margin-bottom: $apx-53; }
.mb-52 { margin-bottom: $apx-52; }
.mb-51 { margin-bottom: $apx-51; }
.mb-50 { margin-bottom: $apx-50; }
.mb-49 { margin-bottom: $apx-49; }
.mb-48 { margin-bottom: $apx-48; }
.mb-47 { margin-bottom: $apx-47; }
.mb-46 { margin-bottom: $apx-46; }
.mb-45 { margin-bottom: $apx-45; }
.mb-44 { margin-bottom: $apx-44; }
.mb-43 { margin-bottom: $apx-43; }
.mb-42 { margin-bottom: $apx-42; }
.mb-41 { margin-bottom: $apx-41; }
.mb-40 { margin-bottom: $apx-40; }
.mb-39 { margin-bottom: $apx-39; }
.mb-38 { margin-bottom: $apx-38; }
.mb-37 { margin-bottom: $apx-37; }
.mb-36 { margin-bottom: $apx-36; }
.mb-35 { margin-bottom: $apx-35; }
.mb-34 { margin-bottom: $apx-34; }
.mb-33 { margin-bottom: $apx-34; }
.mb-32 { margin-bottom: $apx-32; }
.mb-31 { margin-bottom: $apx-31; }
.mb-30 { margin-bottom: $apx-30; }
.mb-29 { margin-bottom: $apx-29; }
.mb-28 { margin-bottom: $apx-28; }
.mb-27 { margin-bottom: $apx-27; }
.mb-26 { margin-bottom: $apx-26; }
.mb-25 { margin-bottom: $apx-25; }
.mb-24 { margin-bottom: $apx-24; }
.mb-23 { margin-bottom: $apx-23; }
.mb-22 { margin-bottom: $apx-22; }
.mb-21 { margin-bottom: $apx-21; }
.mb-20 { margin-bottom: $apx-20; }
.mb-19 { margin-bottom: $apx-19; }
.mb-18 { margin-bottom: $apx-18; }
.mb-17 { margin-bottom: $apx-17; }
.mb-16 { margin-bottom: $apx-16; }
.mb-15 { margin-bottom: $apx-15; }
.mb-14 { margin-bottom: $apx-14; }
.mb-13 { margin-bottom: $apx-13; }
.mb-12 { margin-bottom: $apx-12; }
.mb-11 { margin-bottom: $apx-11; }
.mb-10 { margin-bottom: $apx-10; }
.mb-9 { margin-bottom: $apx-9; }
.mb-8 { margin-bottom: $apx-8; }
.mb-7 { margin-bottom: $apx-7; }
.mb-6 { margin-bottom: $apx-6; }
.mb-5 { margin-bottom: $apx-5; }
.mb-4 { margin-bottom: $apx-4; }
.mb-3 { margin-bottom: $apx-3; }
.mb-2 { margin-bottom: $apx-2; }
.mb-1 { margin-bottom: $apx-1; }

.ml-350 { margin-left: $apx-350; }
.ml-250 { margin-left: $apx-250; }
.ml-100 { margin-left: $apx-100; }
.ml-99 { margin-left: $apx-99; }
.ml-98 { margin-left: $apx-98; }
.ml-97 { margin-left: $apx-97; }
.ml-96 { margin-left: $apx-96; }
.ml-95 { margin-left: $apx-95; }
.ml-94 { margin-left: $apx-94; }
.ml-93 { margin-left: $apx-93; }
.ml-92 { margin-left: $apx-92; }
.ml-91 { margin-left: $apx-91; }
.ml-90 { margin-left: $apx-90; }
.ml-89 { margin-left: $apx-89; }
.ml-88 { margin-left: $apx-88; }
.ml-87 { margin-left: $apx-87; }
.ml-86 { margin-left: $apx-86; }
.ml-85 { margin-left: $apx-85; }
.ml-84 { margin-left: $apx-84; }
.ml-83 { margin-left: $apx-83; }
.ml-82 { margin-left: $apx-82; }
.ml-81 { margin-left: $apx-81; }
.ml-80 { margin-left: $apx-80; }
.ml-79 { margin-left: $apx-79; }
.ml-78 { margin-left: $apx-78; }
.ml-77 { margin-left: $apx-77; }
.ml-76 { margin-left: $apx-76; }
.ml-75 { margin-left: $apx-75; }
.ml-74 { margin-left: $apx-74; }
.ml-73 { margin-left: $apx-73; }
.ml-72 { margin-left: $apx-72; }
.ml-71 { margin-left: $apx-71; }
.ml-70 { margin-left: $apx-70; }
.ml-69 { margin-left: $apx-69; }
.ml-68 { margin-left: $apx-68; }
.ml-67 { margin-left: $apx-67; }
.ml-66 { margin-left: $apx-66; }
.ml-65 { margin-left: $apx-65; }
.ml-64 { margin-left: $apx-64; }
.ml-63 { margin-left: $apx-63; }
.ml-62 { margin-left: $apx-62; }
.ml-61 { margin-left: $apx-61; }
.ml-60 { margin-left: $apx-60; }
.ml-59 { margin-left: $apx-59; }
.ml-58 { margin-left: $apx-58; }
.ml-57 { margin-left: $apx-57; }
.ml-56 { margin-left: $apx-56; }
.ml-55 { margin-left: $apx-55; }
.ml-54 { margin-left: $apx-54; }
.ml-53 { margin-left: $apx-53; }
.ml-52 { margin-left: $apx-52; }
.ml-51 { margin-left: $apx-51; }
.ml-50 { margin-left: $apx-50; }
.ml-49 { margin-left: $apx-49; }
.ml-48 { margin-left: $apx-48; }
.ml-47 { margin-left: $apx-47; }
.ml-46 { margin-left: $apx-46; }
.ml-45 { margin-left: $apx-45; }
.ml-44 { margin-left: $apx-44; }
.ml-43 { margin-left: $apx-43; }
.ml-42 { margin-left: $apx-42; }
.ml-41 { margin-left: $apx-41; }
.ml-40 { margin-left: $apx-40; }
.ml-39 { margin-left: $apx-39; }
.ml-38 { margin-left: $apx-38; }
.ml-37 { margin-left: $apx-37; }
.ml-36 { margin-left: $apx-36; }
.ml-35 { margin-left: $apx-35; }
.ml-34 { margin-left: $apx-34; }
.ml-33 { margin-left: $apx-34; }
.ml-32 { margin-left: $apx-32; }
.ml-31 { margin-left: $apx-31; }
.ml-30 { margin-left: $apx-30; }
.ml-29 { margin-left: $apx-29; }
.ml-28 { margin-left: $apx-28; }
.ml-27 { margin-left: $apx-27; }
.ml-26 { margin-left: $apx-26; }
.ml-25 { margin-left: $apx-25; }
.ml-24 { margin-left: $apx-24; }
.ml-23 { margin-left: $apx-23; }
.ml-22 { margin-left: $apx-22; }
.ml-21 { margin-left: $apx-21; }
.ml-20 { margin-left: $apx-20; }
.ml-19 { margin-left: $apx-19; }
.ml-18 { margin-left: $apx-18; }
.ml-17 { margin-left: $apx-17; }
.ml-16 { margin-left: $apx-16; }
.ml-15 { margin-left: $apx-15; }
.ml-14 { margin-left: $apx-14; }
.ml-13 { margin-left: $apx-13; }
.ml-12 { margin-left: $apx-12; }
.ml-11 { margin-left: $apx-11; }
.ml-10 { margin-left: $apx-10; }
.ml-9 { margin-left: $apx-9; }
.ml-8 { margin-left: $apx-8; }
.ml-7 { margin-left: $apx-7; }
.ml-6 { margin-left: $apx-6; }
.ml-5 { margin-left: $apx-5; }
.ml-4 { margin-left: $apx-4; }
.ml-3 { margin-left: $apx-3; }
.ml-2 { margin-left: $apx-2; }
.ml-1 { margin-left: $apx-1; }

.mr-350 { margin-right: $apx-350; }
.mr-250 { margin-right: $apx-250; }
.mr-100 { margin-right: $apx-100; }
.mr-99 { margin-right: $apx-99; }
.mr-98 { margin-right: $apx-98; }
.mr-97 { margin-right: $apx-97; }
.mr-96 { margin-right: $apx-96; }
.mr-95 { margin-right: $apx-95; }
.mr-94 { margin-right: $apx-94; }
.mr-93 { margin-right: $apx-93; }
.mr-92 { margin-right: $apx-92; }
.mr-91 { margin-right: $apx-91; }
.mr-90 { margin-right: $apx-90; }
.mr-89 { margin-right: $apx-89; }
.mr-88 { margin-right: $apx-88; }
.mr-87 { margin-right: $apx-87; }
.mr-86 { margin-right: $apx-86; }
.mr-85 { margin-right: $apx-85; }
.mr-84 { margin-right: $apx-84; }
.mr-83 { margin-right: $apx-83; }
.mr-82 { margin-right: $apx-82; }
.mr-81 { margin-right: $apx-81; }
.mr-80 { margin-right: $apx-80; }
.mr-79 { margin-right: $apx-79; }
.mr-78 { margin-right: $apx-78; }
.mr-77 { margin-right: $apx-77; }
.mr-76 { margin-right: $apx-76; }
.mr-75 { margin-right: $apx-75; }
.mr-74 { margin-right: $apx-74; }
.mr-73 { margin-right: $apx-73; }
.mr-72 { margin-right: $apx-72; }
.mr-71 { margin-right: $apx-71; }
.mr-70 { margin-right: $apx-70; }
.mr-69 { margin-right: $apx-69; }
.mr-68 { margin-right: $apx-68; }
.mr-67 { margin-right: $apx-67; }
.mr-66 { margin-right: $apx-66; }
.mr-65 { margin-right: $apx-65; }
.mr-64 { margin-right: $apx-64; }
.mr-63 { margin-right: $apx-63; }
.mr-62 { margin-right: $apx-62; }
.mr-61 { margin-right: $apx-61; }
.mr-60 { margin-right: $apx-60; }
.mr-59 { margin-right: $apx-59; }
.mr-58 { margin-right: $apx-58; }
.mr-57 { margin-right: $apx-57; }
.mr-56 { margin-right: $apx-56; }
.mr-55 { margin-right: $apx-55; }
.mr-54 { margin-right: $apx-54; }
.mr-53 { margin-right: $apx-53; }
.mr-52 { margin-right: $apx-52; }
.mr-51 { margin-right: $apx-51; }
.mr-50 { margin-right: $apx-50; }
.mr-49 { margin-right: $apx-49; }
.mr-48 { margin-right: $apx-48; }
.mr-47 { margin-right: $apx-47; }
.mr-46 { margin-right: $apx-46; }
.mr-45 { margin-right: $apx-45; }
.mr-44 { margin-right: $apx-44; }
.mr-43 { margin-right: $apx-43; }
.mr-42 { margin-right: $apx-42; }
.mr-41 { margin-right: $apx-41; }
.mr-40 { margin-right: $apx-40; }
.mr-39 { margin-right: $apx-39; }
.mr-38 { margin-right: $apx-38; }
.mr-37 { margin-right: $apx-37; }
.mr-36 { margin-right: $apx-36; }
.mr-35 { margin-right: $apx-35; }
.mr-34 { margin-right: $apx-34; }
.mr-33 { margin-right: $apx-34; }
.mr-32 { margin-right: $apx-32; }
.mr-31 { margin-right: $apx-31; }
.mr-30 { margin-right: $apx-30; }
.mr-29 { margin-right: $apx-29; }
.mr-28 { margin-right: $apx-28; }
.mr-27 { margin-right: $apx-27; }
.mr-26 { margin-right: $apx-26; }
.mr-25 { margin-right: $apx-25; }
.mr-24 { margin-right: $apx-24; }
.mr-23 { margin-right: $apx-23; }
.mr-22 { margin-right: $apx-22; }
.mr-21 { margin-right: $apx-21; }
.mr-20 { margin-right: $apx-20; }
.mr-19 { margin-right: $apx-19; }
.mr-18 { margin-right: $apx-18; }
.mr-17 { margin-right: $apx-17; }
.mr-16 { margin-right: $apx-16; }
.mr-15 { margin-right: $apx-15; }
.mr-14 { margin-right: $apx-14; }
.mr-13 { margin-right: $apx-13; }
.mr-12 { margin-right: $apx-12; }
.mr-11 { margin-right: $apx-11; }
.mr-10 { margin-right: $apx-10; }
.mr-9 { margin-right: $apx-9; }
.mr-8 { margin-right: $apx-8; }
.mr-7 { margin-right: $apx-7; }
.mr-6 { margin-right: $apx-6; }
.mr-5 { margin-right: $apx-5; }
.mr-4 { margin-right: $apx-4; }
.mr-3 { margin-right: $apx-3; }
.mr-2 { margin-right: $apx-2; }
.mr-1 { margin-right: $apx-1; }

.pt-350 { padding-top: $apx-350; }
.pt-250 { padding-top: $apx-250; }
.pt-100 { padding-top: $apx-100; }
.pt-99 { padding-top: $apx-99; }
.pt-98 { padding-top: $apx-98; }
.pt-97 { padding-top: $apx-97; }
.pt-96 { padding-top: $apx-96; }
.pt-95 { padding-top: $apx-95; }
.pt-94 { padding-top: $apx-94; }
.pt-93 { padding-top: $apx-93; }
.pt-92 { padding-top: $apx-92; }
.pt-91 { padding-top: $apx-91; }
.pt-90 { padding-top: $apx-90; }
.pt-89 { padding-top: $apx-89; }
.pt-88 { padding-top: $apx-88; }
.pt-87 { padding-top: $apx-87; }
.pt-86 { padding-top: $apx-86; }
.pt-85 { padding-top: $apx-85; }
.pt-84 { padding-top: $apx-84; }
.pt-83 { padding-top: $apx-83; }
.pt-82 { padding-top: $apx-82; }
.pt-81 { padding-top: $apx-81; }
.pt-80 { padding-top: $apx-80; }
.pt-79 { padding-top: $apx-79; }
.pt-78 { padding-top: $apx-78; }
.pt-77 { padding-top: $apx-77; }
.pt-76 { padding-top: $apx-76; }
.pt-75 { padding-top: $apx-75; }
.pt-74 { padding-top: $apx-74; }
.pt-73 { padding-top: $apx-73; }
.pt-72 { padding-top: $apx-72; }
.pt-71 { padding-top: $apx-71; }
.pt-70 { padding-top: $apx-70; }
.pt-69 { padding-top: $apx-69; }
.pt-68 { padding-top: $apx-68; }
.pt-67 { padding-top: $apx-67; }
.pt-66 { padding-top: $apx-66; }
.pt-65 { padding-top: $apx-65; }
.pt-64 { padding-top: $apx-64; }
.pt-63 { padding-top: $apx-63; }
.pt-62 { padding-top: $apx-62; }
.pt-61 { padding-top: $apx-61; }
.pt-60 { padding-top: $apx-60; }
.pt-59 { padding-top: $apx-59; }
.pt-58 { padding-top: $apx-58; }
.pt-57 { padding-top: $apx-57; }
.pt-56 { padding-top: $apx-56; }
.pt-55 { padding-top: $apx-55; }
.pt-54 { padding-top: $apx-54; }
.pt-53 { padding-top: $apx-53; }
.pt-52 { padding-top: $apx-52; }
.pt-51 { padding-top: $apx-51; }
.pt-50 { padding-top: $apx-50; }
.pt-49 { padding-top: $apx-49; }
.pt-48 { padding-top: $apx-48; }
.pt-47 { padding-top: $apx-47; }
.pt-46 { padding-top: $apx-46; }
.pt-45 { padding-top: $apx-45; }
.pt-44 { padding-top: $apx-44; }
.pt-43 { padding-top: $apx-43; }
.pt-42 { padding-top: $apx-42; }
.pt-41 { padding-top: $apx-41; }
.pt-40 { padding-top: $apx-40; }
.pt-39 { padding-top: $apx-39; }
.pt-38 { padding-top: $apx-38; }
.pt-37 { padding-top: $apx-37; }
.pt-36 { padding-top: $apx-36; }
.pt-35 { padding-top: $apx-35; }
.pt-34 { padding-top: $apx-34; }
.pt-33 { padding-top: $apx-34; }
.pt-32 { padding-top: $apx-32; }
.pt-31 { padding-top: $apx-31; }
.pt-30 { padding-top: $apx-30; }
.pt-29 { padding-top: $apx-29; }
.pt-28 { padding-top: $apx-28; }
.pt-27 { padding-top: $apx-27; }
.pt-26 { padding-top: $apx-26; }
.pt-25 { padding-top: $apx-25; }
.pt-24 { padding-top: $apx-24; }
.pt-23 { padding-top: $apx-23; }
.pt-22 { padding-top: $apx-22; }
.pt-21 { padding-top: $apx-21; }
.pt-20 { padding-top: $apx-20; }
.pt-19 { padding-top: $apx-19; }
.pt-18 { padding-top: $apx-18; }
.pt-17 { padding-top: $apx-17; }
.pt-16 { padding-top: $apx-16; }
.pt-15 { padding-top: $apx-15; }
.pt-14 { padding-top: $apx-14; }
.pt-13 { padding-top: $apx-13; }
.pt-12 { padding-top: $apx-12; }
.pt-11 { padding-top: $apx-11; }
.pt-10 { padding-top: $apx-10; }
.pt-9 { padding-top: $apx-9; }
.pt-8 { padding-top: $apx-8; }
.pt-7 { padding-top: $apx-7; }
.pt-6 { padding-top: $apx-6; }
.pt-5 { padding-top: $apx-5; }
.pt-4 { padding-top: $apx-4; }
.pt-3 { padding-top: $apx-3; }
.pt-2 { padding-top: $apx-2; }
.pt-1 { padding-top: $apx-1; }

.pb-350 { padding-bottom: $apx-350; }
.pb-250 { padding-bottom: $apx-250; }
.pb-100 { padding-bottom: $apx-100; }
.pb-99 { padding-bottom: $apx-99; }
.pb-98 { padding-bottom: $apx-98; }
.pb-97 { padding-bottom: $apx-97; }
.pb-96 { padding-bottom: $apx-96; }
.pb-95 { padding-bottom: $apx-95; }
.pb-94 { padding-bottom: $apx-94; }
.pb-93 { padding-bottom: $apx-93; }
.pb-92 { padding-bottom: $apx-92; }
.pb-91 { padding-bottom: $apx-91; }
.pb-90 { padding-bottom: $apx-90; }
.pb-89 { padding-bottom: $apx-89; }
.pb-88 { padding-bottom: $apx-88; }
.pb-87 { padding-bottom: $apx-87; }
.pb-86 { padding-bottom: $apx-86; }
.pb-85 { padding-bottom: $apx-85; }
.pb-84 { padding-bottom: $apx-84; }
.pb-83 { padding-bottom: $apx-83; }
.pb-82 { padding-bottom: $apx-82; }
.pb-81 { padding-bottom: $apx-81; }
.pb-80 { padding-bottom: $apx-80; }
.pb-79 { padding-bottom: $apx-79; }
.pb-78 { padding-bottom: $apx-78; }
.pb-77 { padding-bottom: $apx-77; }
.pb-76 { padding-bottom: $apx-76; }
.pb-75 { padding-bottom: $apx-75; }
.pb-74 { padding-bottom: $apx-74; }
.pb-73 { padding-bottom: $apx-73; }
.pb-72 { padding-bottom: $apx-72; }
.pb-71 { padding-bottom: $apx-71; }
.pb-70 { padding-bottom: $apx-70; }
.pb-69 { padding-bottom: $apx-69; }
.pb-68 { padding-bottom: $apx-68; }
.pb-67 { padding-bottom: $apx-67; }
.pb-66 { padding-bottom: $apx-66; }
.pb-65 { padding-bottom: $apx-65; }
.pb-64 { padding-bottom: $apx-64; }
.pb-63 { padding-bottom: $apx-63; }
.pb-62 { padding-bottom: $apx-62; }
.pb-61 { padding-bottom: $apx-61; }
.pb-60 { padding-bottom: $apx-60; }
.pb-59 { padding-bottom: $apx-59; }
.pb-58 { padding-bottom: $apx-58; }
.pb-57 { padding-bottom: $apx-57; }
.pb-56 { padding-bottom: $apx-56; }
.pb-55 { padding-bottom: $apx-55; }
.pb-54 { padding-bottom: $apx-54; }
.pb-53 { padding-bottom: $apx-53; }
.pb-52 { padding-bottom: $apx-52; }
.pb-51 { padding-bottom: $apx-51; }
.pb-50 { padding-bottom: $apx-50; }
.pb-49 { padding-bottom: $apx-49; }
.pb-48 { padding-bottom: $apx-48; }
.pb-47 { padding-bottom: $apx-47; }
.pb-46 { padding-bottom: $apx-46; }
.pb-45 { padding-bottom: $apx-45; }
.pb-44 { padding-bottom: $apx-44; }
.pb-43 { padding-bottom: $apx-43; }
.pb-42 { padding-bottom: $apx-42; }
.pb-41 { padding-bottom: $apx-41; }
.pb-40 { padding-bottom: $apx-40; }
.pb-39 { padding-bottom: $apx-39; }
.pb-38 { padding-bottom: $apx-38; }
.pb-37 { padding-bottom: $apx-37; }
.pb-36 { padding-bottom: $apx-36; }
.pb-35 { padding-bottom: $apx-35; }
.pb-34 { padding-bottom: $apx-34; }
.pb-33 { padding-bottom: $apx-34; }
.pb-32 { padding-bottom: $apx-32; }
.pb-31 { padding-bottom: $apx-31; }
.pb-30 { padding-bottom: $apx-30; }
.pb-29 { padding-bottom: $apx-29; }
.pb-28 { padding-bottom: $apx-28; }
.pb-27 { padding-bottom: $apx-27; }
.pb-26 { padding-bottom: $apx-26; }
.pb-25 { padding-bottom: $apx-25; }
.pb-24 { padding-bottom: $apx-24; }
.pb-23 { padding-bottom: $apx-23; }
.pb-22 { padding-bottom: $apx-22; }
.pb-21 { padding-bottom: $apx-21; }
.pb-20 { padding-bottom: $apx-20; }
.pb-19 { padding-bottom: $apx-19; }
.pb-18 { padding-bottom: $apx-18; }
.pb-17 { padding-bottom: $apx-17; }
.pb-16 { padding-bottom: $apx-16; }
.pb-15 { padding-bottom: $apx-15; }
.pb-14 { padding-bottom: $apx-14; }
.pb-13 { padding-bottom: $apx-13; }
.pb-12 { padding-bottom: $apx-12; }
.pb-11 { padding-bottom: $apx-11; }
.pb-10 { padding-bottom: $apx-10; }
.pb-9 { padding-bottom: $apx-9; }
.pb-8 { padding-bottom: $apx-8; }
.pb-7 { padding-bottom: $apx-7; }
.pb-6 { padding-bottom: $apx-6; }
.pb-5 { padding-bottom: $apx-5; }
.pb-4 { padding-bottom: $apx-4; }
.pb-3 { padding-bottom: $apx-3; }
.pb-2 { padding-bottom: $apx-2; }
.pb-1 { padding-bottom: $apx-1; }

.pl-350 { padding-left: $apx-350; }
.pl-250 { padding-left: $apx-250; }
.pl-100 { padding-left: $apx-100; }
.pl-99 { padding-left: $apx-99; }
.pl-98 { padding-left: $apx-98; }
.pl-97 { padding-left: $apx-97; }
.pl-96 { padding-left: $apx-96; }
.pl-95 { padding-left: $apx-95; }
.pl-94 { padding-left: $apx-94; }
.pl-93 { padding-left: $apx-93; }
.pl-92 { padding-left: $apx-92; }
.pl-91 { padding-left: $apx-91; }
.pl-90 { padding-left: $apx-90; }
.pl-89 { padding-left: $apx-89; }
.pl-88 { padding-left: $apx-88; }
.pl-87 { padding-left: $apx-87; }
.pl-86 { padding-left: $apx-86; }
.pl-85 { padding-left: $apx-85; }
.pl-84 { padding-left: $apx-84; }
.pl-83 { padding-left: $apx-83; }
.pl-82 { padding-left: $apx-82; }
.pl-81 { padding-left: $apx-81; }
.pl-80 { padding-left: $apx-80; }
.pl-79 { padding-left: $apx-79; }
.pl-78 { padding-left: $apx-78; }
.pl-77 { padding-left: $apx-77; }
.pl-76 { padding-left: $apx-76; }
.pl-75 { padding-left: $apx-75; }
.pl-74 { padding-left: $apx-74; }
.pl-73 { padding-left: $apx-73; }
.pl-72 { padding-left: $apx-72; }
.pl-71 { padding-left: $apx-71; }
.pl-70 { padding-left: $apx-70; }
.pl-69 { padding-left: $apx-69; }
.pl-68 { padding-left: $apx-68; }
.pl-67 { padding-left: $apx-67; }
.pl-66 { padding-left: $apx-66; }
.pl-65 { padding-left: $apx-65; }
.pl-64 { padding-left: $apx-64; }
.pl-63 { padding-left: $apx-63; }
.pl-62 { padding-left: $apx-62; }
.pl-61 { padding-left: $apx-61; }
.pl-60 { padding-left: $apx-60; }
.pl-59 { padding-left: $apx-59; }
.pl-58 { padding-left: $apx-58; }
.pl-57 { padding-left: $apx-57; }
.pl-56 { padding-left: $apx-56; }
.pl-55 { padding-left: $apx-55; }
.pl-54 { padding-left: $apx-54; }
.pl-53 { padding-left: $apx-53; }
.pl-52 { padding-left: $apx-52; }
.pl-51 { padding-left: $apx-51; }
.pl-50 { padding-left: $apx-50; }
.pl-49 { padding-left: $apx-49; }
.pl-48 { padding-left: $apx-48; }
.pl-47 { padding-left: $apx-47; }
.pl-46 { padding-left: $apx-46; }
.pl-45 { padding-left: $apx-45; }
.pl-44 { padding-left: $apx-44; }
.pl-43 { padding-left: $apx-43; }
.pl-42 { padding-left: $apx-42; }
.pl-41 { padding-left: $apx-41; }
.pl-40 { padding-left: $apx-40; }
.pl-39 { padding-left: $apx-39; }
.pl-38 { padding-left: $apx-38; }
.pl-37 { padding-left: $apx-37; }
.pl-36 { padding-left: $apx-36; }
.pl-35 { padding-left: $apx-35; }
.pl-34 { padding-left: $apx-34; }
.pl-33 { padding-left: $apx-34; }
.pl-32 { padding-left: $apx-32; }
.pl-31 { padding-left: $apx-31; }
.pl-30 { padding-left: $apx-30; }
.pl-29 { padding-left: $apx-29; }
.pl-28 { padding-left: $apx-28; }
.pl-27 { padding-left: $apx-27; }
.pl-26 { padding-left: $apx-26; }
.pl-25 { padding-left: $apx-25; }
.pl-24 { padding-left: $apx-24; }
.pl-23 { padding-left: $apx-23; }
.pl-22 { padding-left: $apx-22; }
.pl-21 { padding-left: $apx-21; }
.pl-20 { padding-left: $apx-20; }
.pl-19 { padding-left: $apx-19; }
.pl-18 { padding-left: $apx-18; }
.pl-17 { padding-left: $apx-17; }
.pl-16 { padding-left: $apx-16; }
.pl-15 { padding-left: $apx-15; }
.pl-14 { padding-left: $apx-14; }
.pl-13 { padding-left: $apx-13; }
.pl-12 { padding-left: $apx-12; }
.pl-11 { padding-left: $apx-11; }
.pl-10 { padding-left: $apx-10; }
.pl-9 { padding-left: $apx-9; }
.pl-8 { padding-left: $apx-8; }
.pl-7 { padding-left: $apx-7; }
.pl-6 { padding-left: $apx-6; }
.pl-5 { padding-left: $apx-5; }
.pl-4 { padding-left: $apx-4; }
.pl-3 { padding-left: $apx-3; }
.pl-2 { padding-left: $apx-2; }
.pl-1 { padding-left: $apx-1; }

.pr-350 { padding-right: $apx-350; }
.pr-250 { padding-right: $apx-250; }
.pr-100 { padding-right: $apx-100; }
.pr-99 { padding-right: $apx-99; }
.pr-98 { padding-right: $apx-98; }
.pr-97 { padding-right: $apx-97; }
.pr-96 { padding-right: $apx-96; }
.pr-95 { padding-right: $apx-95; }
.pr-94 { padding-right: $apx-94; }
.pr-93 { padding-right: $apx-93; }
.pr-92 { padding-right: $apx-92; }
.pr-91 { padding-right: $apx-91; }
.pr-90 { padding-right: $apx-90; }
.pr-89 { padding-right: $apx-89; }
.pr-88 { padding-right: $apx-88; }
.pr-87 { padding-right: $apx-87; }
.pr-86 { padding-right: $apx-86; }
.pr-85 { padding-right: $apx-85; }
.pr-84 { padding-right: $apx-84; }
.pr-83 { padding-right: $apx-83; }
.pr-82 { padding-right: $apx-82; }
.pr-81 { padding-right: $apx-81; }
.pr-80 { padding-right: $apx-80; }
.pr-79 { padding-right: $apx-79; }
.pr-78 { padding-right: $apx-78; }
.pr-77 { padding-right: $apx-77; }
.pr-76 { padding-right: $apx-76; }
.pr-75 { padding-right: $apx-75; }
.pr-74 { padding-right: $apx-74; }
.pr-73 { padding-right: $apx-73; }
.pr-72 { padding-right: $apx-72; }
.pr-71 { padding-right: $apx-71; }
.pr-70 { padding-right: $apx-70; }
.pr-69 { padding-right: $apx-69; }
.pr-68 { padding-right: $apx-68; }
.pr-67 { padding-right: $apx-67; }
.pr-66 { padding-right: $apx-66; }
.pr-65 { padding-right: $apx-65; }
.pr-64 { padding-right: $apx-64; }
.pr-63 { padding-right: $apx-63; }
.pr-62 { padding-right: $apx-62; }
.pr-61 { padding-right: $apx-61; }
.pr-60 { padding-right: $apx-60; }
.pr-59 { padding-right: $apx-59; }
.pr-58 { padding-right: $apx-58; }
.pr-57 { padding-right: $apx-57; }
.pr-56 { padding-right: $apx-56; }
.pr-55 { padding-right: $apx-55; }
.pr-54 { padding-right: $apx-54; }
.pr-53 { padding-right: $apx-53; }
.pr-52 { padding-right: $apx-52; }
.pr-51 { padding-right: $apx-51; }
.pr-50 { padding-right: $apx-50; }
.pr-49 { padding-right: $apx-49; }
.pr-48 { padding-right: $apx-48; }
.pr-47 { padding-right: $apx-47; }
.pr-46 { padding-right: $apx-46; }
.pr-45 { padding-right: $apx-45; }
.pr-44 { padding-right: $apx-44; }
.pr-43 { padding-right: $apx-43; }
.pr-42 { padding-right: $apx-42; }
.pr-41 { padding-right: $apx-41; }
.pr-40 { padding-right: $apx-40; }
.pr-39 { padding-right: $apx-39; }
.pr-38 { padding-right: $apx-38; }
.pr-37 { padding-right: $apx-37; }
.pr-36 { padding-right: $apx-36; }
.pr-35 { padding-right: $apx-35; }
.pr-34 { padding-right: $apx-34; }
.pr-33 { padding-right: $apx-34; }
.pr-32 { padding-right: $apx-32; }
.pr-31 { padding-right: $apx-31; }
.pr-30 { padding-right: $apx-30; }
.pr-29 { padding-right: $apx-29; }
.pr-28 { padding-right: $apx-28; }
.pr-27 { padding-right: $apx-27; }
.pr-26 { padding-right: $apx-26; }
.pr-25 { padding-right: $apx-25; }
.pr-24 { padding-right: $apx-24; }
.pr-23 { padding-right: $apx-23; }
.pr-22 { padding-right: $apx-22; }
.pr-21 { padding-right: $apx-21; }
.pr-20 { padding-right: $apx-20; }
.pr-19 { padding-right: $apx-19; }
.pr-18 { padding-right: $apx-18; }
.pr-17 { padding-right: $apx-17; }
.pr-16 { padding-right: $apx-16; }
.pr-15 { padding-right: $apx-15; }
.pr-14 { padding-right: $apx-14; }
.pr-13 { padding-right: $apx-13; }
.pr-12 { padding-right: $apx-12; }
.pr-11 { padding-right: $apx-11; }
.pr-10 { padding-right: $apx-10; }
.pr-9 { padding-right: $apx-9; }
.pr-8 { padding-right: $apx-8; }
.pr-7 { padding-right: $apx-7; }
.pr-6 { padding-right: $apx-6; }
.pr-5 { padding-right: $apx-5; }
.pr-4 { padding-right: $apx-4; }
.pr-3 { padding-right: $apx-3; }
.pr-2 { padding-right: $apx-2; }
.pr-1 { padding-right: $apx-1; }

@media screen and ( max-width: 960px ) {

  .flex-col-m { flex-direction: column; }
  .text-center-m { text-align: center; }
  .flex-col-reverse-m { flex-direction: column-reverse; }
  
  .mt-276 { margin-top: $apx-m-5; }
  .mt-220 { margin-top: $apx-m-26; }
  .mt-140 { margin-top: $apx-m-50; }
  .mt-95 { margin-top: $apx-m-66; }
  .mt-94 { margin-top: $apx-m-65; }
  .mt-93 { margin-top: $apx-m-64; }
  .mt-90 { margin-top: $apx-m-62; }
  .mt-89 { margin-top: $apx-m-61; }
  .mt-88 { margin-top: $apx-m-60; }
  .mt-80 { margin-top: $apx-m-50; }
  .mt-73 { margin-top: $apx-m-46; }
  .mt-64 { margin-top: $apx-m-34; }
  .mt-60-m { margin-top: $apx-m-60; }
  .mt-59 { margin-top: $apx-m-28; }
  .mt-55 { margin-top: $apx-m-24; }
  .mt-54 { margin-top: $apx-m-55; }
  .mt-50 { margin-top: $apx-m-48; }
  .mt-50-m { margin-top: $apx-m-50; }
  .mt-43 { margin-top: $apx-m-43; }
  .mt-42-m { margin-top: $apx-m-42; }
  .mt-41-m { margin-top: $apx-m-41; }
  .mt-40-m { margin-top: $apx-m-40; }
  .mt-40 { margin-top: $apx-m-18; }
  .mt-38 { margin-top: $apx-m-38; }
  .mt-36-m { margin-top: $apx-m-36; }
  .mt-35-m { margin-top: $apx-m-35; }
  .mt-33-m { margin-top: $apx-m-33; }
  .mt-33-m { margin-top: $apx-m-33; }
  .mt-32-m { margin-top: $apx-m-32; }
  .mt-31-m { margin-top: $apx-m-31; }
  .mt-30-m { margin-top: $apx-m-30; }
  .mt-29-m { margin-top: $apx-m-29; }
  .mt-28-m { margin-top: $apx-m-28; }
  .mt-27-m { margin-top: $apx-m-27; }
  .mt-26-m { margin-top: $apx-m-26; }
  .mt-25-m { margin-top: $apx-m-25; }
  .mt-24-m { margin-top: $apx-m-24; }
  .mt-23-m { margin-top: $apx-m-23; }
  .mt-22-m { margin-top: $apx-m-22; }
  .mt-21-m { margin-top: $apx-m-21; }
  .mt-20-m { margin-top: $apx-m-20; }
  .mt-19-m { margin-top: $apx-m-19; }
  .mt-18-m { margin-top: $apx-m-18; }
  .mt-17-m { margin-top: $apx-m-17; }
  .mt-16-m { margin-top: $apx-m-16; }
  .mt-15-m { margin-top: $apx-m-15; }
  .mt-14-m { margin-top: $apx-m-14; }
  .mt-13-m { margin-top: $apx-m-13; }
  .mt-12-m { margin-top: $apx-m-12; }
  .mt-11-m { margin-top: $apx-m-11; }
  .mt-10-m { margin-top: $apx-m-10; }
  .mt-9-m { margin-top: $apx-m-9; }
  .mt-8-m { margin-top: $apx-m-8; }
  .mt-7-m { margin-top: $apx-m-7; }
  .mt-6-m { margin-top: $apx-m-6; }
  .mt-5-m { margin-top: $apx-m-5; }
  .mt-4-m { margin-top: $apx-m-4; }
  .mt-3-m { margin-top: $apx-m-3; }
  .mt-2-m { margin-top: $apx-m-2; }
  .mt-1-m { margin-top: $apx-m-1; }

  .mb-30-m { margin-bottom: $apx-m-30; }
  .mb-20-m { margin-bottom: $apx-m-20; }
  .mb-15-m { margin-bottom: $apx-m-15; }

  .mr-50-m { margin-right: $apx-m-50; }
  .mr-40-m { margin-right: $apx-m-40; }
  .mr-35-m { margin-right: $apx-m-35; }
  .mr-33-m { margin-right: $apx-m-33; }
  .mr-30-m { margin-right: $apx-m-30; }
  .mr-25-m { margin-right: $apx-m-25; }
  .mr-20-m { margin-right: $apx-m-20; }
  .mr-19-m { margin-right: $apx-m-19; }
  .mr-18-m { margin-right: $apx-m-18; }
  .mr-17-m { margin-right: $apx-m-17; }
  .mr-16-m { margin-right: $apx-m-16; }
  .mr-15-m { margin-right: $apx-m-15; }
  .mr-14-m { margin-right: $apx-m-14; }
  .mr-13-m { margin-right: $apx-m-13; }
  .mr-12-m { margin-right: $apx-m-12; }
  .mr-11-m { margin-right: $apx-m-11; }
  .mr-10-m { margin-right: $apx-m-10; }
  .mr-5-m { margin-right: $apx-m-5; }

  .ml-50-m { margin-left: $apx-m-50; }
  .ml-40-m { margin-left: $apx-m-40; }
  .ml-35-m { margin-left: $apx-m-35; }
  .ml-33-m { margin-left: $apx-m-33; }
  .ml-30-m { margin-left: $apx-m-30; }
  .ml-25-m { margin-left: $apx-m-25; }
  .ml-20-m { margin-left: $apx-m-20; }
  .ml-19-m { margin-left: $apx-m-19; }
  .ml-18-m { margin-left: $apx-m-18; }
  .ml-17-m { margin-left: $apx-m-17; }
  .ml-16-m { margin-left: $apx-m-16; }
  .ml-15-m { margin-left: $apx-m-15; }
  .ml-14-m { margin-left: $apx-m-14; }
  .ml-13-m { margin-left: $apx-m-13; }
  .ml-12-m { margin-left: $apx-m-12; }
  .ml-11-m { margin-left: $apx-m-11; }
  .ml-10-m { margin-left: $apx-m-10; }
  .ml-5-m { margin-left: $apx-m-5; }

  .mb-25-m { margin-bottom: $apx-m-25; }
  .mb-16-m { margin-bottom: $apx-m-16; }
  .mb-15-m { margin-bottom: $apx-m-15; }
  .mb-14-m { margin-bottom: $apx-m-14; }
  .mb-13-m { margin-bottom: $apx-m-13; }
  .mb-12-m { margin-bottom: $apx-m-12; }
  .mb-11-m { margin-bottom: $apx-m-11; }
  .mb-10-m { margin-bottom: $apx-m-10; }
  .mb-5-m { margin-bottom: $apx-m-5; }
  .mb-4-m { margin-bottom: $apx-m-4; }
  .mb-3-m { margin-bottom: $apx-m-3; }
  .mb-2-m { margin-bottom: $apx-m-2; }
  .mb-1-m { margin-bottom: $apx-m-1; }
  
  .pl-40 { padding-left: $apx-m-40; }
  .pl-30 { padding-left: $apx-m-30; }
  .pl-20 { padding-left: $apx-m-20; }
  .pl-10 { padding-left: $apx-m-10; }

}